import moment from "moment-timezone";
import _ from "lodash";
import Logo from '../images/logo.png'
import { format_address,paymentMethodPosted, sort_by_descending ,customStartCase,numberFormat } from "../constants/common";

   const reArrangOrdersData = (orderData) => {
    let order = orderData;
    let reArrangOrders = [];
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if (item?.orderBreakDownItems?.isminimumPrice) {
            let obj = {
              fixedItems: [
                {
                  fixedItem: "Dumping services",
                  unitPrice: item?.orderBreakDownItems?.totalAmountDue,
                  units: 1,
                  totalamout: item?.orderBreakDownItems?.totalAmountDue,
                },
              ],
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              isSalesTax: item.isSalesTax,
              salesTaxFeeAmount: item.salesTaxFeeAmount,
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType,
              isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
              createdAt: item?.createdAt
            };
            reArrangOrders.push(obj);
            return null;
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType,
                isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item?.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
                isSalesTax: item.isSalesTax,
                salesTaxFeeAmount: item.salesTaxFeeAmount,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
                createdAt: item?.createdAt
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems?.fixedItem);
                  if (fixedItemName.includes("Yard")) {
                    if (
                      Number(fixedItems?.unitPrice) > Number(item?.orderBreakDownItems?.capoutPrice)
                    ) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout:
                          round2decimal(item?.orderBreakDownItems?.capoutPrice *
                          fixedItems.units),
                      };
                      obj.fixedItems.push(fixedItemObject);
                    } else {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: fixedItems?.unitPrice,
                        units: fixedItems.units,
                        totalamout:
                          round2decimal(fixedItems?.unitPrice *
                          fixedItems.units),
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: round2decimal(fixedItems?.unitPrice * fixedItems.units),
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightFixedItems) => {
                    let chargeByWeightItemObject = {
                      fixedItem: chargeByWeightFixedItems?.fixedItem,
                      unitPrice: chargeByWeightFixedItems?.unitPrice,
                      units: chargeByWeightFixedItems?.units,
                      totalamout:
                        round2decimal(chargeByWeightFixedItems?.unitPrice *
                        chargeByWeightFixedItems?.units),
                    };

                    obj.fixedItems.push(chargeByWeightItemObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType,
                isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item?.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
                isSalesTax: item.isSalesTax,
                salesTaxFeeAmount: item.salesTaxFeeAmount,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
                createdAt: item?.createdAt
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: round2decimal(fixedItems?.unitPrice * fixedItems?.units),
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightItems) => {
                    let chargeByWeightItemsObject = {
                      fixedItem: chargeByWeightItems?.fixedItem,
                      unitPrice: chargeByWeightItems?.unitPrice,
                      units: chargeByWeightItems?.units,
                      totalamout:
                        round2decimal(chargeByWeightItems?.unitPrice *
                        chargeByWeightItems?.units),
                    };
                    obj.fixedItems.push(chargeByWeightItemsObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          let total = 0
          let dumpRate=0
         let isCapOutPrice=(item?.pricingTierCapoutPrice > 0)
         let units = 0
         if (!item?.isMinimumPrice) {
           if (item?.dumpRateType?.indexOf("Yard") !== -1) {
             units = Number(item?.yardage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate= Number(item.pricingTierCapoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                total = round2decimal(Number(item?.yardage) * Number(item?.dumpRate))
               }
             } else{
               dumpRate=Number(item?.dumpRate)
                total = round2decimal(Number(item?.yardage) * Number(item?.dumpRate))
             }
           } else {
             units = Number(item?.tonnage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate=Number(item?.capoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                 total = round2decimal(Number(item?.tonnage) * Number(item?.dumpRate))
               }
             } else{
               dumpRate=Number(item?.dumpRate)
               total = round2decimal(Number(item?.tonnage) * Number(item?.dumpRate))
             }
           }
           let obj = {
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(dumpRate),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             paymentType:item?.paymentType,
             stripeCardFees:item?.stripeCardFees,
             totalAmount: total,
             units: units,
             dumpRateType: item?.dumpRateType,
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             isSalesTax: item.isSalesTax,
             salesTaxFeeAmount: item.salesTaxFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             tonnage:item?.tonnage,
             createdAt:item?.createdAt
           };
           reArrangOrders.push(obj);
         } else {
           let obj = {
             isMinimumPrice: item?.isMinimumPrice,
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(item?.totalAmount),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             paymentType:item?.paymentType,
             stripeCardFees:item?.stripeCardFees,
             totalAmount: item?.totalAmount,
             units: 1,
             dumpRateType: "Dumping services",
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             isSalesTax: item.isSalesTax,
             salesTaxFeeAmount: item.salesTaxFeeAmount,
             tonnage:item?.tonnage,
             createdAt:item?.createdAt
           };
           reArrangOrders.push(obj);
         }
        }
        return null;
      });
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders;
  };

const round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };
const  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

 const numberWithCommas = (x) => {
    x = parseFloat(x)
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  
  // Invoice Print Receipt 
  export const  PrintInvoiceReceipt=(items)=>{
    let { order, customer } = items;
    const ordersData =sort_by_descending(reArrangOrdersData(order), "createdAt");
    const Company = items.company && items?.company[0];
    let body1 = 
    `
    <div>
     <style>
      @media print {
        html {
          size: A4;
          // page-break-after: avoid;
        }
        @page {
          page-break-after: always;
          page-break-before: always;
          margin: 0mm;
        }
        .invoice__each-transaction {
          page-break-after: always;
          page-break-before: always;
          position: relative;
          visibility: visible;
          left: 0;
          top: 0;
        }
        .print-table-block {
          page-break-after: auto;
          // height: 100vh;
          position: relative;
        }
        .print-block {
          display: block;
          page-break-before:always;
      }
     .breakcontent {
        page-break-after: always;
        page-break-before: always;
      }
    }

    </style>
     <style>
    .bill-description-table th,.bill-description-table td{
    font-family: 'Arial';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-align: left;
    border-bottom: 1px solid #000000;
    padding: 10px 17px;
    word-wrap: break-word;
    min-width: 72px;
    vertical-align: text-top;
     }
  .bill-description-table th{
     font-weight: 700;
  }     
 
  .bill-description-table tr td:first-child,.bill-description-table tr th:first-child{
    padding-left: 0px;
  }
  .bill-description-table tr td:last-child,.bill-description-table tr th:last-child{
    padding-right: 0px;
    text-align: right;
    white-space: nowrap;
  }
  .amount-number{
      font-family: 'Arial';
      font-weight: 400;
      font-size: 35px;
      line-height: 53px;
      color: #000000;
      margin: 0px;
      white-space: nowrap;
    }
    .bill-description-footer-table tr td{
      border: 0px;
    }

    .invoice-number-date-table td:first-child{
      vertical-align: text-top;
    }
    .ticket-col-table{
      width: 100%;
    }
    .ticket-col-table td{
      border-bottom: 0px;
      padding: 0px;
    }
    .ticket-col-table td:first-child{
      min-width: 60px !important;
    }
    .ticket-col-table td:last-child{
      min-width: 120px;
    }        
    .desc-list-items {
      
    }
    .desc-list-items p {
     display: block;
     white-space: nowrap; 
     font-size: 10px;
     font-weight: 600;
    }
    .desc-list-items p span {
      white-space: nowrap; 
      font-weight: 400;
     } 
     .main__logo{
      max-height: 50px;
      max-width: 250px;
      margin-top:12px;
     }  
  </style>
 <div className="breakcontent">
<table width="100%" style="page-break-after: always; max-width: 1024px; margin: 0px auto;padding:0px 15px 15px 15px; background-color: white !important; z-index: 9999 !important;">
<thead>
  <tr>
    <th colspan="3" style="text-align: left;">
      <img class="main__logo" src=${Company?.logo?.length > 0 ? Company?.logo : Logo}>
    </th>
  </tr>
  <!-- Gap Tr Start -->
  <tr>
    <td style="height: 20px;"></td>
  </tr>
  <tr>
    <th colspan="3">
      <p
      style="
      font-family: Arial;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      color: #000000;
      margin-bottom: 41px;
      " 
      >${Company?.businessInfo?.address}
      <br />
      ${format_address(Company?.businessInfo)}
      <br />
      ${Company?.billingEmail}</P>
    </th>
  </tr>
</thead>    
<tbody>
  <tr>
  <td style="vertical-align: top; padding-right: 30px; max-width: 200px;">
  ${customer?.displayName?.length > 0 ? `<div
  style="
  font-family: Arial;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
  margin: 0px;
  width: 180px;
  "
  >${customer?.displayName}</div>` : ""}
    ${customer?.address?.length > 0 ? 
    `<div
      style="
      font-family: Arial;
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      color: #000000;
      margin: 0px;
      width: 180px;
      "
      >${customer?.address}</div>` : ""}
      <div
      style="
        font-family: Arial;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #000000;
        word-break: break-all;
        margin: 0px;
        width: 180px;
        "
      > ${format_address(customer)?.length > 0 ? `${format_address(customer)} <br />`: ""}
      ${customer?.phone?.length > 0 ? ` ${phoneFormate(customer?.phone)} <br />`: "" }
       ${customer?.email?.length > 0 ? customer?.email : customer?.billingEmailAddress}

                </div>
    </td>
    <td style="vertical-align: top; padding-right: 30px;">
      <table class="invoice-number-date-table">
        <tbody>
          <tr>
            <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 600;
              font-size: 13px;
              line-height: 15px;
              color: #000000;
              min-width: 127px;
              margin: 0px;
              vertical-align: top;
              "
            >Invoice Number:</h3>
            </td>
            <td>
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
              vertical-align: top;
              min-width: 127px;
              margin: 0px;
              "
            > ${items?.invoiceId ?? ""}                  
            </p>
            </td>
          </tr>
          <tr>
          <td>
          <h3
            style="
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            color: #000000;
            display: inline;
            min-width: 127px;
            margin: 0px;
            vertical-align: top;
            "
          >Invoice Date: </h3>
          </td>
          <td>
          <p
            style="
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            vertical-align: top;
            display: inline;
            min-width: 127px;
            margin: 0px;
            "
          > ${moment(items?.invoiceDate)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}               
          </p>
          </td>
        </tr>
          <tr>
            <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 600;
              font-size: 13px;
              line-height: 15px;
              color: #000000;
              display: inline;
              min-width: 127px;
              margin: 0px;
              vertical-align: top;
              "
            >Due Date: </h3>
            </td>
            <td>
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
              vertical-align: top;
              display: inline;
              min-width: 127px;
              margin: 0px;
              "
            > ${items && items?.dueDate ? moment(items?.dueDate)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l") : 'N/A'}                  
            </p>
            </td>
          </tr>
          ${items.PO ? `<tr>
          <td>
          <h3
            style="
            font-family: Arial;
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            color: #000000;
            display: inline;
            min-width: 127px;
            margin: 0px;
            vertical-align: top;
            "
          >PO: </h3>
          </td>
          <td>
          <p
            style="
            font-family: Arial;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            vertical-align: top;
            display: inline;
            min-width: 127px;
            margin: 0px;
            "
          > ${items.PO}               
          </p>
          </td>
        </tr>` : ""}
         ${items.manifest ? `<tr>
         <td>
         <h3
           style="
           font-family: Arial;
           font-weight: 600;
           font-size: 13px;
           line-height: 15px;
           color: #000000;
           display: inline;
           min-width: 127px;
           margin: 0px;
           vertical-align: top;
           "
         >Manifest: </h3>
         </td>
         <td>
         <p
           style="
           font-family: Arial;
           font-weight: 400;
           font-size: 12px;
           line-height: 15px;
           color: #000000;
           vertical-align: top;
           display: inline;
           min-width: 127px;
           margin: 0px;
           "
         > ${items?.manifest}               
         </p>
         </td>
       </tr>` :""}
        </tbody>
      </table>                       
    </td>
    <td 
    style="vertical-align: top;"
    >
    <h3
    style="
    font-family: Arial;
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    color: #000000;
    display: inline-block;
    min-width: 127px;
    margin: 0px;
    "
  >Balance</h3>
    <h2 class="amount-number"   
      > $${numberWithCommas(
     round2decimal(Number(items?.balacnce))
    )}</h2>
    </td>
  </tr>
  <!-- Tr End  -->
  <!-- Gap Tr Start -->
  <tr>
    <td style="height: 32px;"></td>
  </tr>
  <!-- Gap Tr End -->
  <!-- Tr Start  -->
  ${items?.notes
      ? `
  <tr>
    <td colspan="3">
      <h3 style="
      font-family: Arial;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
        margin: 0px 0px 4px 0px;
      ">Notes:</h3>
      <p style="
      font-family: Arial;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        margin: 0px;
        white-space: pre-line;
      ">
        ${items?.notes ?? ""}
      </p>
    </td>
`
      : ""
    }
  </tr>
  <!-- Tr End  -->
  <!-- Gap Tr Start -->
  <tr>
    <td style="height: 32px;"></td>
  </tr>
  <!-- Gap Tr End -->
  <tr>
    <td colspan="3">
    <table class="bill-description-table" width="100%" style="border-collapse: collapse;">
        <thead>
          <tr>
            <th>
              Date
            </th>
            <th>
              SKU
            </th>
            <th>
            Description
            </th>
            <th>
            Unit
            </th>
            <th>
            Rate 
            </th>
            <th>
            Amount 
            </th>
          </tr>
        </thead> 
         ${ordersData && ordersData.length > 0 ? sort_by_descending(ordersData, "createdAt")
      ?.map(
        (item, ind) =>
          `
         ${item?.orderCreationType === "createCharge"
            ? !item.isMinimumPrice ? `
                    <tbody>
          ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
              .map(
                (orderItem, index) =>
                  `
              <tr>
             <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
             <td style="white-space: nowrap;">${orderItem?.fixedItem ?? ""}</td>

             <td>                      
                <div class="desc-list-items">
                 <p>Ticket: <span>#${item?.ticketNumber ?? ""}</span></p>
               </div> 
             </td>
             <td>${orderItem?.units}</td>
             <td>$${numberWithCommas(
                    Number(orderItem?.unitPrice)
                  )}</td>
             <td style="white-space: nowrap;">$${numberWithCommas(
                    Number(orderItem?.totalamout)
                  )}</td>
             </tr>
             `
              )
              .join(""): ""}
              ${item?.isEnvironmentalLoadFee ?
                `${` <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Environmental Load Fee Amount</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                    </tr>`
                 }` :""}
                ${item?.isFuelSurchardeFee ?
                `${` <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Fuel Surcharge Fee Amount</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>$${numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                      </tr>`
               }`:""}
              ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                    `${` <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Handling Fee</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                          </tr>`
                  }` :""}
              </tbody>
             `:`
             <tbody>
   ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
       .map(
         (orderItem, index) =>
           `
       <tr>
      <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
      <td style="white-space: nowrap;">${orderItem?.fixedItem ?? ""}</td>

      <td>                      
         <div class="desc-list-items">
          <p>Ticket: <span>#${item?.ticketNumber ?? ""}</span></p>
        </div> 
      </td>
      <td>${orderItem?.units}</td>
      <td>$${numberWithCommas(
             Number(orderItem?.unitPrice)
           )}</td>
      <td style="white-space: nowrap;">$${numberWithCommas(
             Number(orderItem?.totalamout)
           )}</td>
      </tr>
      `
       )
       .join(""): ""}
       ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
             `${` <tr>
                   <td>&nbsp;</td>
                   <td>&nbsp;</td>
                   <td>Handling Fee</td>
                   <td>&nbsp;</td>
                   <td>&nbsp;</td>
                   <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                   </tr>`
           }` :""}
       </tbody>
      `
            : ` ${ !item?.isMinimumPrice ?
                `
                  <tbody>
                   <tr>
                    <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("MM/DD/YYYY")}</td>
                    <td style="white-space: nowrap;">${customStartCase(
                    item?.dumpRateType
                    )}</td>
                        <td>                      
                            <div class="desc-list-items">
                              <p>Ticket: <span>#${item?.ticketNumber
                    }</span></p>
                              <p>Inbound Weight = <span>${numberWithCommas(
                    Number(item?.inboundWeight)
                  )}</span></p>
                              <p>Outbound Weight = <span>${numberWithCommas(
                    Number(item?.outboundWeight)
                  )}</span></p>
                              <p>Net Weight = <span>${numberWithCommas(
                    Number(item?.netWeight)
                  )}</span></p>
                            </div> 
                        </td> 
                        <td> ${numberWithCommas(Number(item?.units))}</td>
                        <td>$${numberWithCommas(
                    Number(item?.dumpRate)
                  )} </td>
                        <td>$${numberWithCommas(item?.totalAmount)}
                      </td>
                      </tr>
                 ${item?.isEnvironmentalLoadFee ?
                  `${` <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Environmental Load Fee Amount</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                      </tr>`
                   }` :""}
                  ${item?.isFuelSurchardeFee ?
                  `${` <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Fuel Surcharge Fee Amount</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>$${numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                        </tr>`
                 }`:""}
                 ${ Company?.chargeStripeCardFees && (item.stripeCardFees > 0) ?
                    `${` <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Handling Fee</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                          </tr>`
                  }` :""}
                 </tbody>
                 ${item?.additionalItems?.length > 0 ? item?.additionalItems.map((additionalItem) => `
                  <tbody>
                   <tr>
                    <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
                    <td style="white-space: nowrap;">${customStartCase(additionalItem?.label)}</td>
                    <td>                      
                        <div class="desc-list-items">
                          <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                        </div> 
                    </td> 
                    <td> ${numberWithCommas(Number(additionalItem?.quantity))}</td>
                    <td>$${numberWithCommas(Number(additionalItem?.value))} </td>
                    <td>$${numberWithCommas(Number(additionalItem?.quantity) * Number(additionalItem?.value))}</td>
                   </tr>
                 </tbody>
                 ` ).join("") : ""} `
              : `${
                `
                <tbody>
                <tr>
                 <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
                 <td style="white-space: nowrap;">${customStartCase(
                 item?.dumpRateType
                 )}</td>
                     <td>                      
                         <div class="desc-list-items">
                           <p>Ticket: <span>#${item?.ticketNumber
                 }</span></p>
                           <p>Inbound Weight = <span>${numberWithCommas(
                 Number(item?.inboundWeight)
               )}</span></p>
                           <p>Outbound Weight = <span>${numberWithCommas(
                 Number(item?.outboundWeight)
               )}</span></p>
                           <p>Net Weight = <span>${numberWithCommas(
                 Number(item?.netWeight)
               )}</span></p>
                         </div> 
                     </td> 
                     <td> ${item?.units
               }</td>
                     <td>$${numberWithCommas(
                 Number(item?.dumpRate)
               )} </td>
                     <td>$${numberWithCommas(item?.totalAmount)}
                   </td>
                   </tr>
                   ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                    `${` <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Handling Fee</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                          </tr>`
                  }` :""}
              </tbody>`}` } `
          } `
      )
      .join(""): ""}
                <tfoot class="bill-description-footer-table" style="border-top: 1px solid #000;">
                <!-- Gap Tr Start -->
                <tr>
                    <td style="height: 20px;"></td>
                </tr>
                <!-- Gap Tr End -->
                <tr>
                <td colspan="2" style="vertical-align: top;">
                  <div style="margin-bottom: 4px;">
                    <span style=" 
                    font-family: Arial;
                    font-size: 1px;
                    line-height: 18px;
                    color: #000000;
                    margin: 0px;
                    display: inline;
                    min-width: 120px;
                    ">Account Type:</span>
                    <span 
                    style=" 
                    font-family: Arial;
                      font-weight: 400;
                      font-size: 1px;
                      line-height: 18px;
                      color: #000000;
                      margin: 0px;
                      display: inline-block;
                    "
                    > ${ customer?.paymentTerms ==="account"? "On Account" : _.startCase(customer?.paymentTerms.toUpperCase())}</span>
                  </div>
                    </td>
                    <td colspan="4" style="vertical-align: top;">
                    <table style="
                        margin-left: auto;
                        ">                                 
                        ${Company?.chargeStripeCardFees &&
                           (items?.stripeCardFees!==null) && items?.stripeCardFees > 0
                          ?`<tr>
                   
                        <td style="text-align: right; padding: 4px 0px 4px 10px;">
                          <h3 style=" 
                          font-family: Arial;
                          font-weight: 700;
                          font-size: 16.5px;
                          line-height: 18px;
                          color: #000000;
                          margin: 0px;
                          display: inline-block;                          
                          ">Handling fee</h3>
                            <span
                              style=" font-size: 18.8px;
                              "
                          >$${numberWithCommas(Number(items?.stripeCardFees)
                            )}
                        </span>
                    </td>
                  </tr>`:""}
                      <tr>
                        <td style="text-align: right; padding: 4px 0px 4px 10px;">
                          <h3 style=" 
                          font-family: Arial;
                          font-weight: 700;
                          font-size: 16.5px;
                          line-height: 18px;
                          color: #000000;
                          margin: 0px;
                          display: inline-block;    
                          ">Total</h3>
                          <span
                            style=" font-size: 18.8px;
                            "
                        >$${numberWithCommas(
                        round2decimal(Number(items?.total))
                        )}  
                      </span>
                    </td>
                  </tr>
                </table>                     
              </td>
            </tr>
          </tfoot>
        </table>
      </td>
    </tr>
  </tbody>
</table>
</div>
  `;
return body1;
}


// Empire Print Invoice Receipt 
export const  PrintSisterCompanyInvoiceReceipt=(items,sisterCompanyData)=>{
  let { order, customer } = items;
  const ordersData =sort_by_descending(reArrangOrdersData(order), "createdAt");
  const Company = items.company && items?.company[0];
  let body1 = 
  `
  <div>
   <style>
    @media print {
      html {
        size: A4;
        margin: 0mm;
        // page-break-after: avoid;
      }
      @page {
        page-break-after: always;
        page-break-before: always;
        margin: 0mm;
      }
      .invoice__each-transaction {
        page-break-after: always;
        page-break-before: always;
        position: relative;
        visibility: visible;
        left: 0;
        top: 0;
      }
      .print-table-block {
        page-break-after: auto;
        // height: 100vh;
        position: relative;
      }
      .print-block {
        display: block;
        page-break-before:always;
    }
   .breakcontent {
      page-break-after: always;
      page-break-before: always;
    }
    thead {display: table-row-group;}
  }

  </style>
  <style>
    .bill-description-table th,.bill-description-table td{
      font-family: Arial;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    text-align: left;
    // border-bottom: 1px solid #000000;
    padding: 4px 17px;
    word-wrap: break-word;
    min-width: 72px;
    vertical-align: text-top;
    }
  .bill-description-table th{
    font-weight: 600;
  }     
  .bill-description-table tr:first-child th,
  .bill-description-table tr:first-child td,
  .bill-description-table tr:last-child th,
  .bill-description-table tr:last-child td {
    padding: 4px 17px;
  }

  .bill-description-table tr:first-child th,
  .bill-description-table tr:first-child td {
    padding-top: 10px;
  }

  .bill-description-table tr:last-child th,
  .bill-description-table tr:last-child td {
    padding-bottom: 10px;
  }


  .bill-description-table tr td:first-child,.bill-description-table tr th:first-child{
    padding-left: 0px;
  }
  .bill-description-table tr td:last-child,.bill-description-table tr th:last-child{
    padding-right: 0px;
    text-align: right;
    white-space: nowrap;
  }
  .amount-number{
      font-family: 'Arial';
      font-weight: 400;
      font-size: 35px;
      line-height: 53px;
      color: #000000;
      margin: 0px;
      white-space: nowrap;
    }
    .bill-description-footer-table tr td{
      border: 0px;
    }

    .invoice-number-date-table td:first-child{
      // max-width: 127px;
      // min-width: 127px;
      vertical-align: text-top;
      text-align: right;
    }
    .ticket-col-table{
      width: 100%;
    }
    .ticket-col-table td{
      border-bottom: 0px;
      padding: 0px;
    }
    .ticket-col-table td:first-child{
      min-width: 60px !important;
    }
    .ticket-col-table td:last-child{
      min-width: 120px;
    }        
    .desc-list-items {
      
    }
    .desc-list-items p {
    display: block;
    white-space: nowrap; 
    font-size: 10px;
    font-weight: 600;
    padding:0px;
    }
    .desc-list-items p span {
      white-space: nowrap; 
      font-weight: 400;
    } 
    .desc-list-items p {
      margin: 0;
      padding: 4px 0;
    }
    .desc-list-items p:first-child {
      padding:0px 0px 5px 0px;
    }
    .desc-list-items p:last-child {
      padding: 0px 0px 0px 0px;
    }
    .main__logo{
      max-height: 50px;
      max-width: 250px;
    }  
    .empire__logo{
      max-height: 55px;
      max-width: 100%;
    }
    .border-top{
      border-top:1px solid #000000;
    }
    .tdForFontSize td{
      font-size: 10px
    }
  </style>
  <div className="breakcontent">
    <table width="100%" style="
      page-break-after: always; 
      max-width: 1024px; 
      margin: 0px auto;
      padding:0px 15px 15px 15px;
      background-color: white !important;
      z-index:9999 !important;
      table-layout: fixed;">
    <thead>
    <tr style="vertical-align:top;">
      <th style="width: 25%;">
        <div style="max-width: 200px;">
          <h4 style="
            font-family: 'Arial';
            font-size: 13px;
            font-weight: 600;
            text-align: left;
            text-decoration: underline;
            margin: 5px 0px 7px;
          ">Location</h4>
          <p
            style="font-family: 'Arial';
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            text-align: left;
            color: #000000;
            margin-top: 0px;
            margin-bottom: 30px;
            " 
          >${Company?.businessInfo?.address}
          <br />
          ${format_address(Company?.businessInfo)} <br/>${phoneFormate(Company?.businessInfo?.phone)}</P>
        </div>
      </th>
    <th style="width: 50%;text-align: center;">
      <img class="main__logo- empire__logo" style="padding-bottom:20px;" src=${Company.logo.length > 0 ? Company.logo : Logo}>
      <h4 style="    
        font-family: 'Arial';
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.7;
        margin: 0 auto;
        margin-bottom:10px;
          ">79-51 Cooper Avenue <br/>Glendale, NY 11385<br/> 718.424.0300 ext 126</h4>     
    </th>
    <th style="width: 25%;">
      <div style="max-width: 164px; margin-left: auto;">
        <h4 style=" 
        font-family: 'Arial';
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline; 
        text-align: right;
        margin: 5px 0px 7px;
        ">Location</h4>
        <p
        style="font-family: 'Arial';
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: right;
        color: #000000;
        margin-top: 0px;
        margin-bottom: 30px;
        " 
        >
        ${sisterCompanyData?.businessInfo?.address}
          <br />
          ${format_address(sisterCompanyData?.businessInfo)}
           </br>${ sisterCompanyData?.businessInfo?.phone.length > 0 ? phoneFormate(sisterCompanyData?.businessInfo?.phone) : ""} 
        </P>
      </div>
    </th>
    </tr>
  </thead>    
  <tbody>
<tr>
<td colspan="2"  style="vertical-align: top;">
  ${customer?.displayName?.length > 0 ? `<div
  style="
  font-family: Arial;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  color: #000000;
  margin: 0px;
  width: 270px;
  white-space: nowrap;
  "
  >${customer?.displayName}</div>` : ""}
  ${customer?.address?.length > 0 ? 
  `<div
    style="
    font-family: Arial;
    font-size: 12px;
    line-height: normal;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
    width: 270px;
    margin: 5px 0px 0px 0px;
    "
    >${customer?.address}</div>` : ""}
    <div
    style="
      font-family: Arial;
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      color: #000000;
      word-break: break-all;
      margin:0px;
      width: 270px;
      "
    > <div>${format_address(customer)?.length > 0 ? `${format_address(customer)}`: ""}</div>
     <div>${customer?.phone?.length > 0 && customer?.phone !=="undefined" && customer?.phone !=="null"? ` ${phoneFormate(customer?.phone)}`: "" }</div>
     <div>${customer?.email?.length > 0 ? customer?.email : customer?.billingEmailAddress?.length > 0 ? customer?.billingEmailAddress :""}</div>
    </div>
  </td>

  <td style="vertical-align: top;">
    <table class="invoice-number-date-table" style="vertical-align: top; width:100%;border-spacing: 0;">
      <tbody>
        <tr>
          <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              margin: 0px;
              vertical-align: top;
              white-space: nowrap;
              "
            >Invoice Number: </h3>
          </td>
          <td style="text-align: right;">
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              vertical-align: top;
              margin: 0px;
              "
            > ${items?.invoiceId ?? ""}                  
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              display: inline;
              margin: 0px;
              vertical-align: top;
              "
            >Invoice Date :</h3>
          </td>
          <td style="text-align: right;">
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              vertical-align: top;
              display: inline;
              margin: 0px;
              "
            > ${moment(items?.invoiceDate)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}               
            </p>
          </td>
          <tr>
          <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              display: inline;
              min-width: 127px;
              margin: 0px;
              vertical-align: top;
              "
            >Frequency:</h3>
          </td>
          <td style="text-align: right;">
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #000000;
              vertical-align: top;
              display: inline;
              min-width: 127px;
              margin: 0px;
              white-space: nowrap;
              "
            > ${items?.invoiceFrequency === "auto" ? "Each Transaction" :items?.invoiceFrequency.charAt(0).toUpperCase() + items?.invoiceFrequency.slice(1)}                
            </p>
          </td>
        </tr>
        ${items.PO ? `<tr>
        <td>
          <h3
            style="
            font-family: Arial;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
            display: inline;
            min-width: 127px;
            margin: 0px;
            vertical-align: top;
            "
          >PO: </h3>
        </td>
        <td>
        <p
          style="
          font-family: Arial;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          vertical-align: top;
          display: inline;
          min-width: 127px;
          margin: 0px;
          "
        > ${items.PO}               
        </p>
        </td>
      </tr>` : ""}
       ${items.manifest ? `<tr>
       <td>
       <h3
         style="
         font-family: Arial;
         font-weight: 700;
         font-size: 12px;
         line-height: 18px;
         color: #000000;
         display: inline;
         min-width: 127px;
         margin: 0px;
         vertical-align: top;
         "
       >Manifest: </h3>
       </td>
       <td>
       <p
         style="
         font-family: Arial;
         font-weight: 400;
         font-size: 12px;
         line-height: 18px;
         color: #000000;
         vertical-align: top;
         display: inline;
         min-width: 127px;
         margin: 0px;
         "
       > ${items.manifest}               
       </p>
       </td>
     </tr>` :""}
      </tbody>
    </table>                       
  </td> 
</tr>
<!-- Tr End  -->
<!-- Tr Start  -->
${items.notes
    ? `
<tr>
  <td colspan="3">
    <h3 style="
    font-family: Arial;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin: 0px 0px 4px 0px;
    ">Notes:</h3>
    <p style="
    font-family: Arial;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      margin: 0px;
      white-space: pre-line;
    ">
      ${items?.notes ?? ""}
    </p>
  </td>
`
    : ""
  }
</tr>
<!-- Tr End  -->
<tr>
  <td colspan="3">
  <table class="bill-description-table" width="100%" style="border-collapse: collapse;margin-top:20px;">
      <thead>
        <tr>
          <th>
            Date
          </th>
          <th>
            Ticket Num.
          </th>
          <th>
          Description
          </th>
          <th>
          Unit
          </th>
          <th>
          Rate 
          </th>
          <th>
          Amount 
          </th>
        </tr>
      </thead> 
       ${ordersData && ordersData.length > 0 ? ordersData
    ?.map(
      (item, ind) =>
        `
       ${item?.orderCreationType === "createCharge"
          ? !item.isMinimumPrice ? `
                  <tbody class="border-top tdForFontSize">
          ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
            .map(
              (orderItem, index) =>
                `
            <tr>
           <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
           <td>                      
              ${item?.ticketNumber ?? ""}
           </td>
           <td style="white-space: nowrap;">${orderItem?.fixedItem ?? ""}</td>
           <td>${orderItem?.units}</td>
           <td>$${numberWithCommas(
                  Number(orderItem?.unitPrice)
                )}</td>
           <td style="white-space: nowrap;">$${numberWithCommas(
                  Number(orderItem?.totalamout)
                )}</td>
           </tr>
           `
            )
            .join(""): ""}
            ${item?.isEnvironmentalLoadFee ?
              `${` <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>Environmental Load Fee</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                  </tr>`
               }` :""}
              ${item?.isFuelSurchardeFee ?
              `${` <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Fuel Surcharge Fee</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>$${numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                    </tr>`
             }`:""}
            ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                  `${` <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Handling Fee</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                        </tr>`
                }` :""}
            </tbody>
           `:`
           <tbody class="border-top tdForFontSize">
 ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
     .map(
       (orderItem, index) =>
         `
     <tr>
    <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
    <td>                      
    <div class="desc-list-items">
    ${item?.ticketNumber ?? ""}
   </div> 
    </td>
    <td style="white-space: nowrap;">${orderItem?.fixedItem ?? ""}</td>
    <td>${orderItem?.units}</td>
    <td>$${numberWithCommas(
           Number(orderItem?.unitPrice)
         )}</td>
    <td style="white-space: nowrap;">$${numberWithCommas(
           Number(orderItem?.totalamout)
         )}</td>
    </tr>
    `
     )
     .join(""): ""}
     ${ Company?.chargeStripeCardFees && item?.stripeCardFees > 0 ?
           `${` <tr>
                 <td>&nbsp;</td>
                 <td>&nbsp;</td>
                 <td>Handling Fee</td>
                 <td>&nbsp;</td>
                 <td>&nbsp;</td>
                 <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                 </tr>`
         }` :""}
     </tbody>
    `
          : ` ${ !item?.isMinimumPrice ?
              `
                <tbody class='tdForFontSize'>
                 <tr class="border-top">
                  <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("MM/DD/YYYY")}</td>
                  <td style="white-space: nowrap;">
                  ${item?.ticketNumber ?? ""}
                  </td>
                      <td>                      
                          <div class="desc-list-items">
                           <p><span>${customStartCase(
                            item?.dumpRateType
                            )}</span></p>
                          </div> 
                      </td> 
                      <td> ${numberWithCommas(Number(item?.units))}</td>
                      <td>$${numberWithCommas(
                  Number(item?.dumpRate)
                )} </td>
                      <td>$${numberWithCommas(item?.totalAmount)}
                    </td>
                    </tr>
               ${item?.isEnvironmentalLoadFee ?
                `${` <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Environmental Load Fee</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                    </tr>`
                 }` :""}
                ${item?.isFuelSurchardeFee ?
                `${` <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Fuel Surcharge Fee</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>$${numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                      </tr>`
               }`:""}
               ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0 ) ?
                  `${` <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Handling Fee</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                        </tr>`
                }` :""}
               </tbody>
               ${item?.additionalItems?.length > 0 ? item?.additionalItems.map((additionalItem) => `
                <tbody class="tdForFontSize">
                 <tr>
                  <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
                  <td style="white-space: nowrap;">${customStartCase(additionalItem?.label)}</td>
                  <td>                      
                  ${item?.ticketNumber ?? ""}
                  </td> 
                  <td> ${numberWithCommas(Number(additionalItem?.quantity))}</td>
                  <td>$${numberWithCommas(Number(additionalItem?.value))} </td>
                  <td>$${numberWithCommas(Number(additionalItem?.quantity) * Number(additionalItem?.value))}</td>
                 </tr>
               </tbody>
               ` ).join("") : ""} `
            : `${
              `
              <tbody class="tdForFontSize">
              <tr class="border-top">
               <td>${moment(item?.createdAt)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}</td>
               <td style="white-space: nowrap;"><p><span>${item?.ticketNumber
               }</span></p></td>
                   <td>                      
                       <div class="desc-list-items">
                       <p> <span>${customStartCase(
                        item?.dumpRateType
                        )}</span></p>
                       </div> 
                   </td> 
                   <td> ${item?.units
             }</td>
                   <td>$${numberWithCommas(
               Number(item?.dumpRate)
             )} </td>
                   <td>$${numberWithCommas(item?.totalAmount)}
                 </td>
                 </tr>
                 ${ Company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                  `${` <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Handling Fee</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                        </tr>`
                }` :""}
            </tbody>`}` } `
        } `
    )
    .join(""): ""}
              <tbody class="bill-description-footer-table" style="border-top: 1px solid #000; background-color: white !important; z-index:9999 !important;">
              <!-- Gap Tr Start -->
              <tr>
                  <td style="height: 20px;"></td>
              </tr>
              <!-- Gap Tr End -->
              <tr>
              <td colspan="2" style="vertical-align: top;">
                <div style="margin-bottom: 4px; white-space: nowrap;">
                  <span style=" 
                  font-family: Arial;
                  font-size: 12px;
                  line-height: 18px;
                  color: #000000;
                  margin: 0px;
                  display: inline;
                  min-width: 120px;
                  ">Account Type:</span>
                  <span 
                  style=" 
                  font-family: Arial;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                    margin: 0px;
                    display: inline-block;
                  "
                  > ${_.startCase(customer?.paymentTerms.toUpperCase())==="ACCOUNT" ?"On Account" : _.startCase(customer?.paymentTerms.toUpperCase())}</span>
                </div>
                  
                  ${customer?.paymentTerms !== "account" &&
                    customer?.paymentType === "credit-card"
                    ? `
                      <span style=" 
                      font-family: Arial;
                      font-size: 12px;
                      line-height: 18px;
                      color: #000000;
                      margin: 0px;
                      display: inline-block;
                      ">Credit Card:</span>
                      <span 
                      style=" 
                      font-family: Arial;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #000000;
                          margin: 0px;
                          display: inline;
                      "
                      >${customer?.paymentType.toUpperCase()}</span>
                      `
                          : ""
                        }
                  </td>
                  <td colspan="4" style="vertical-align: top;">
                  <table style="
                      margin-left: auto;
                      ">                                 
                      ${Company?.chargeStripeCardFees &&
                         (items?.stripeCardFees!==null)
                        ?`<tr>
                      <td style="text-align: right; padding: 4px 0px 4px 10px;">
                        <h3 style=" 
                        font-family: Arial;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 18px;
                        color: #000000;
                        margin: 0px;
                        display: inline-block;
                        ">Handling fee</h3>
                          <p 
                          style=" 
                          font-family: Arial;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #000000;
                          margin: 0px;
                          display: inline-block;
                          "
                      >$${numberWithCommas(Number(items?.stripeCardFees)
                        )}
                  </p>
                  </td>
                </tr>`:""}
                      <tr>
                      <td style="text-align: right; padding: 4px 0px 4px 10px;">
                        <h3 style=" 
                        font-family: Arial;
                        font-weight: 700;
                        font-size: 16.5px;
                        line-height: 18px;
                        color: #000000;
                        margin: 0 0 7px;
                        display: inline-block;
                        ">Total:
                        <span style="font-size:1.3em;">$${numberWithCommas(
                        round2decimal(Number(items?.total))
                        )}</span>
                        </h3>
                        <h3 style="margin:4px 0px 0px 0px;">Balance due upon Receipt: 
                          <span style="font-size:1.3em;">$${numberWithCommas(
                          round2decimal(Number(items?.balacnce))
                          )}</span>
                        </h3>
                  </td>
                </tr>
              </table>                     
            </td>
          </tr>
           
        </tbody>
      </table>
    </td>
  </tr>
</tbody>
</table>
</div>
`;
return body1;
}

// PerForated Order 
const reArrangpPerforatedOrdersData = (orderData) => {
  let order = orderData;
  let reArrangOrders = [];
  if (order.length > 0) {
    order.map((item) => {
      if (_.get(item, "orderCreationType", "") === "createCharge") {
        if (_.get(item, "orderBreakDownItems.isminimumPrice", false)) {
          let obj = {
            fixedItems: [
              {
                fixedItem: "Dumping services",
                unitPrice: item.orderBreakDownItems.totalAmountDue,
                units: 1,
                totalamout: item.orderBreakDownItems.totalAmountDue,
              },
            ],
            ticketNumber: item.ticketNumber,
            orderCreationType: item.orderCreationType,
            isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
          };
          reArrangOrders.push(obj);
          return null;
        } else {
          if (item.orderBreakDownItems.isCapOutPrice) {
            let obj = {
              fixedItems: [],
              ticketNumber: item.ticketNumber,
              orderCreationType: item.orderCreationType,
              stripeCardFees:item.stripeCardFees,
              paymentType:item.paymentType,
              isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
            };
            if (item.orderBreakDownItems.fixedItem.length > 0) {
              item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                const fixedItemName = customStartCase(fixedItems.fixedItem);
                if (fixedItemName.includes("Yard")) {
                  if (
                    Number(fixedItems.unitPrice) > Number(item.orderBreakDownItems.capoutPrice)
                  ) {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: item.orderBreakDownItems.capoutPrice,
                      units: fixedItems.units,
                      totalamout:
                        round2decimal(item.orderBreakDownItems.capoutPrice * fixedItems.units),
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems.unitPrice,
                      units: fixedItems.units,
                      totalamout:
                       round2decimal( fixedItems.unitPrice *
                        fixedItems.units),
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                } else {
                  let fixedItemObject = {
                    fixedItem: fixedItems.fixedItem,
                    unitPrice: fixedItems.unitPrice,
                    units: fixedItems.units,
                    totalamout: round2decimal(fixedItems.unitPrice * fixedItems.units),
                  };
                  obj.fixedItems.push(fixedItemObject);
                }
                return null;
              });
            }
            if (item.orderBreakDownItems.chargeByWeight.length > 0) {
              item.orderBreakDownItems.chargeByWeight.map(
                (chargeByWeightFixedItems) => {
                  let chargeByWeightItemObject = {
                    fixedItem: chargeByWeightFixedItems.fixedItem,
                    unitPrice: chargeByWeightFixedItems.unitPrice,
                    units: chargeByWeightFixedItems.units,
                    totalamout:
                      round2decimal(chargeByWeightFixedItems.unitPrice *
                      chargeByWeightFixedItems.units),
                  };

                  obj.fixedItems.push(chargeByWeightItemObject);
                  return null;
                }
              );
            }
            reArrangOrders.push(obj);
          } else {
            let obj = {
              fixedItems: [],
              ticketNumber: item.ticketNumber,
              orderCreationType: item.orderCreationType,
              stripeCardFees:item.stripeCardFees,
              paymentType:item.paymentType,
              isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
            };
            if (item.orderBreakDownItems.fixedItem.length > 0) {
              item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                let fixedItemObject = {
                  fixedItem: fixedItems.fixedItem,
                  unitPrice: fixedItems.unitPrice,
                  units: fixedItems.units,
                  totalamout: round2decimal(fixedItems.unitPrice * fixedItems.units),
                };
                obj.fixedItems.push(fixedItemObject);
                return null;
              });
            }
            if (item.orderBreakDownItems.chargeByWeight.length > 0) {
              item.orderBreakDownItems.chargeByWeight.map(
                (chargeByWeightItems) => {
                  let chargeByWeightItemsObject = {
                    fixedItem: chargeByWeightItems.fixedItem,
                    unitPrice: chargeByWeightItems.unitPrice,
                    units: chargeByWeightItems.units,
                    totalamout:
                      round2decimal(chargeByWeightItems.unitPrice * chargeByWeightItems.units),
                  };
                  obj.fixedItems.push(chargeByWeightItemsObject);
                  return null;
                }
              );
            }
            reArrangOrders.push(obj);
          }
        }
      } else {
        let total = 0
        let isCapOutPrice=(item.pricingTierCapoutPrice > 0)
          let units = 0
          if (!item.isMinimumPrice) {
            if (item.dumpRateType.indexOf("Yard") !== -1) {
              units = Number(item.yardage)
              if (item.tonnageDifference < 0) {
                if(isCapOutPrice){
                  total = Number(item.capoutPrice)
                }else{
                 total = round2decimal(Number(item.yardage) * Number(item.dumpRate))
                }
              } else{
                 total = round2decimal(Number(item.yardage) * Number(item.dumpRate))
              }
            } else {
              units = Number(item.tonnage)
              if (item.tonnageDifference < 0) {
                if(isCapOutPrice){
                  total = Number(item.capoutPrice)
                }else{
                  total =round2decimal(Number(item.tonnage) * Number(item.dumpRate))
                }
              } else{
                total = round2decimal(Number(item.tonnage) * Number(item.dumpRate))
              }
            }
            let obj = {
              inboundWeight: item.inboundWeight,
              outboundWeight: item.outboundWeight,
              tonnageDifference: item.tonnageDifference,
              isCapped: item.isCapped,
              additionalItems: item.additionalItems,
              // dumpRateType: item.dumpRateType,
              dumpRate: Number(item.dumpRate),
              // ticketNumber: item.ticketNumber,
              netWeight: item.netWeight,
              // yardage: item.yardage,
              // tonnage: item.tonnage,
              // totalAmount: item.totalAmount,
              paymentType:item.paymentType,
              stripeCardFees:item.stripeCardFees,
              totalAmount: total,
              units: units,
              dumpRateType: item.dumpRateType,
              ticketNumber: item.ticketNumber,
              isEnvironmentalLoadFee: item.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item.fuelSurchargeTotal,
              isSalesTax : item.isSalesTax,
              salesTaxFeeAmount : item.salesTaxFeeAmount,
              createdAt: item.createdAt,
            };
            reArrangOrders.push(obj);
          } else {
            let obj = {
              isMinimumPrice: item.isMinimumPrice,
              inboundWeight: item.inboundWeight,
              outboundWeight: item.outboundWeight,
              tonnageDifference: item.tonnageDifference,
              isCapped: item.isCapped,
              additionalItems: item.additionalItems,
              // dumpRateType: item.dumpRateType,
              dumpRate: Number(item.totalAmount),
              // ticketNumber: item.ticketNumber,
              netWeight: item.netWeight,
              // yardage: item.yardage,
              // tonnage: item.tonnage,
              // totalAmount: item.totalAmount,
              totalAmount: item.totalAmount,
              units: 1,
              dumpRateType: "Dumping services",
              ticketNumber: item.ticketNumber,
              isEnvironmentalLoadFee: item.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item.fuelSurchargeTotal,
              isSalesTax : item.isSalesTax,
              salesTaxFeeAmount : item.salesTaxFeeAmount,
              createdAt: item.createdAt
            };
            reArrangOrders.push(obj);
          }
      }
      return null;
    });
  }
  // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
  return reArrangOrders;
};

// PerForated invoice
  export const  PrintPerforatedInvoice=(items,sisterCompany)=>{
    const arrangeOrderData = reArrangpPerforatedOrdersData([items]);
    const {company} = items
    let html = `
      <html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Print Invoice</title>
    <style>
      body {
       margin: 0;
       font-size: 11px;
       line-height: 1.4;
       font-family: Arial, sans-serif;
      }

      p {
       margin: 0;
      }

      .print-default-table {
       width: 100%;
       border-collapse: collapse;
      }

      .print-default-table th,
      .print-default-table td {
       font-size: 11px;
       vertical-align: top;
      }

      .print-table {
       width: 100%;
       border-collapse: collapse;
      }

      .print-table th,
      .print-table td {
       border-bottom: 1px solid #000;
       padding: 4px;
       font-size: 11px;
       font-weight: 400;
       text-align: left;
      }

      .print-table th:first-child,
      .print-table th:last-child {
       padding: 4px 0;
      }

      .print-table td:first-child,
      .print-table td:last-child {
       padding: 4px 0;
      }

      .print-table thead th {
       font-weight: 600;
      }

      .print-table thead th:last-child {
       text-align: right;
      }

      .print-table tbody td:last-child {
       text-align: right;
      }

      .print-table-tbody-footer tr td {
       border-bottom: 0 solid #000;
      }

      .print-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      .print-view-top,
      .print-view-bottom {
        flex: 1;
      }
      .print-view-bottom-inner {

      }

    </style>
  </head>

  <body>
    <div class="print-view">
      <div class="print-view-top">
        ${sisterCompany && typeof sisterCompany === 'object' && Object.keys(sisterCompany).length !== 0 ? `
        <table class="print-default-table" style="margin-bottom: 5px;">
          <tbody>
            <tr>
              <td>
                <strong style=" text-decoration: underline; margin-bottom: 5px; display: block; "> Location </strong>
                <p style=" line-height: 1.4; ">
                  ${company.businessInfo.address}<br />
                  ${format_address(company.businessInfo)}<br />
                  ${company.businessInfo.phone && company.businessInfo.phone.length > 0 ? ` ${phoneFormate(company.businessInfo.phone)} <br />
                  `: ""}
                </p>
              </td>
              <td style="text-align: center;">
                <div style="display: inline-block;text-align: center;margin-bottom: 5px;"><img style="max-width:300px;" src="${company.logo.length > 0 ? company.logo : 'Logo'}" /></div>
                <div>
                  <p style=" line-height: 1.4; ">
                    79-51 Cooper Avenue <br />
                    Glendale, NY 11385<br />
                    718.424.0300 ext 126
                  </p>
                </div>
              </td>
              <td style="text-align: right;">
                <div>
                  <strong style=" text-decoration: underline; margin-bottom: 5px; display: block; "> Location </strong>
                  <p style=" line-height: 1.4; ">
                    ${sisterCompany.businessInfo.address}<br />
                    ${format_address(sisterCompany.businessInfo)} <br />
                    ${sisterCompany.businessInfo.phone && sisterCompany.businessInfo.phone.length > 0 ? ` ${phoneFormate(sisterCompany.businessInfo.phone)} <br />
                    `: ""}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        ` : `
        <table class="print-default-table" style="margin-bottom: 5px;">
          <tbody>
            <tr>
              <td>
                <div style="display: block;text-align: left;margin-bottom: 5px;"><img style="max-height: 50px; max-width: 250px;" src="${company?.logo?.length > 0 ? company?.logo : 'Logo'}" /></div>
                <p style=" line-height: 1.4; ">
                  <strong>${company?.businessInfo?.businessName ?? ""}</strong><br />
                  ${company?.businessInfo?.address ?? ""} <br />
                  ${format_address(company?.businessInfo)} <br />
                  ${company?.sisterCompany ? '' : company?.billingEmail} <br />
                  ${company?.businessInfo?.phone ?? ""}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        ` }
        <table class="print-default-table" style="margin-bottom: 5px; line-height: 1.4;">
          <tbody>
            <tr>
              <td>
                <table class="print-default-table">
                  <tbody>
                    <tr>
                      <td><strong>Vehicle:</strong></td>
                      <td>
                        ${(items?.driver && items?.driver?.licenseNumber) || (items?.truck && items?.truck?.plateNumber) ? ` ${_.get(items, "driver.licenseNumber", "")} ${items?.driver ? (items?.driver?.licenseNumber
        && items?.truck?.plateNumber ? " | " : "") : ''} ${_.get(items, "truck.plateNumber", "")}` : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Customer:</strong></td>
                      <td>${items.customer.displayName}</td>
                    </tr>
                    <tr>
                      <td><strong>Account Type:</strong></td>
                      <td>${items.paymentTerms.toUpperCase() === "COD" ? `COD ${items.cardNumber ? `- ${items.cardNumber.slice(-4)}` : ""}` : "On Account"}</td>
                    </tr>
                    <tr>
                      <td><strong>Driver:</strong></td>
                      <td>${items?.driver ? items?.driver.firstName + " " + items?.driver.lastName : "N/A"}</td>
                    </tr>
                    <tr>
                      <td><strong>Origin City:</strong></td>
                      <td>${items.cityOfOrigin ? items.cityOfOrigin : "N/A"}</td>
                    </tr>
                    <tr>
                      <td><strong>Project:</strong></td>
                      <td>${items?.projects ? items?.projects?.projectName : "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="print-default-table">
                  <tbody>
                    <tr>
                      <td><strong>Ticket Number:</strong></td>
                      <td>${items.ticketNumber}</td>
                    </tr>
                    <tr>
                      <td><strong>Date:</strong></td>
                      <td>${moment(items.completedDate)?.tz(items.company?.timezone)?.format("l")}</td>
                    </tr>
                    <tr>
                      <td><strong>Inbound Time:</strong></td>
                      <td>${items?.orderCreationType !== "createCharge" ? moment(items.inboundWeightDate)?.tz(items.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                    </tr>
                    <tr>
                      <td><strong>Outbound Time:</strong></td>
                      <td>${items?.orderCreationType !== "createCharge" ? moment(items.outboundWeightDate)?.tz(items.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                    </tr>
                    <tr>
                      <td><strong>Payment Type:</strong></td>
                      <td>
                        ${_.get(items, "paymentType", "") === "" ? _.get(items, "splitPayment", []).length > 0 ? `${items.splitPayment.map((payment, index) => (`${_.startCase(payment.paymentType) + (index <
          items.splitPayment.length - 1 ? ", " : " ")}`)).join("")}` : items.paymentTerms === "account" ? "Account" : "N/A" : `${_.startCase(_.get(items, "paymentType", "N/A"))}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="print-default-table">
                  <tbody>
                    ${_.get(items, "hideWeight", false) === false ? `
                    <tr>
                      <td><strong>Gross:</strong></td>
                      <td>${items.inboundWeight} lbs</td>
                    </tr>
                    <tr>
                      <td><strong>Tare:</strong></td>
                      <td>${items.outboundWeight} lbs</td>
                    </tr>
                    <tr>
                      <td><strong>Net:</strong></td>
                      <td>${items.netWeight} lbs</td>
                    </tr>
                    <tr>
                      <td><strong>Tons:</strong></td>
                      <td>${items.tonnage}</td>
                    </tr>
                    ` : ""}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="print-table" style=" line-height: 1.4;">
          <thead>
            <tr>
              <th>Materials & Services</th>
              <th>Description</th>
              <th>Unit</th>
              ${!(items?.customer?.hidePrice) ? `
              <th>Rate</th>
              <th>Amount</th>
              `: ""}
            </tr>
          </thead>
          ${arrangeOrderData.length > 0 ? arrangeOrderData.map((item) => ` ${item.orderCreationType === "createCharge" ? !item.isMinimumPrice ? `
          <tbody>
            ${item.fixedItems && item.fixedItems.length > 0 ? item.fixedItems.map((orderItem) => `
            <tr>
              <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
              <td>
                <div class="desc-list-items">
                  <p>Ticket: <span>#${item.ticketNumber}</span></p>
                  ${items.customerRefrence && items.customerRefrence !== "" ? `
                  <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                  ` : ""}
                </div>
              </td>
              <td>${orderItem.units}</td>
              ${!(items?.customer?.hidePrice) ? `
              <td>$${numberWithCommas(Number(orderItem.unitPrice))}</td>
              <td style="white-space: nowrap;text-align:right;">$${numberWithCommas(Number(orderItem.totalamout))}</td>
              `: ""}
            </tr>
            ` ).join("") : ""} ${!(items?.customer?.hidePrice) ? ` ${items?.isEnvironmentalLoadFee ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Environmental Load Fee Amount</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(items?.environmentalLoadFeeAmount ?? 0))}</td>
            </tr>
            ` }` : ""} ${items?.isFuelSurchardeFee ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Fuel Surcharge Fee Amount</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(items?.fuelSurchardeFeeAmount ?? 0))}</td>
            </tr>
            ` }` : ""} ${items?.company.chargeStripeCardFees && (item?.stripeCardFees > 0) ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Handling Fee</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
            </tr>
            ` }` : ""} ${items?.isSalesTax && items.salesTaxFeeAmount > 0 ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Sales Tax</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(items?.salesTaxFeeAmount ?? 0))}</td>
            </tr>
            ` }` : ""} ` : ""}
          </tbody>
          `: `
          <tbody>
            ${item.fixedItems && item.fixedItems.length > 0 ? item.fixedItems.map((orderItem) => `
            <tr>
              <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
              <td>
                <div class="desc-list-items">
                  <p>Ticket: <span>#${item.ticketNumber}</span></p>
                  ${items.customerRefrence && items.customerRefrence !== "" ? `
                  <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                  ` : ""}
                </div>
              </td>
              <td>${orderItem.units}</td>
              ${!(items?.customer?.hidePrice) ? `
              <td>${items.isSalesTax ? `$${numberWithCommas(Number(orderItem.unitPrice - items.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(orderItem.unitPrice))}`}</td>
              <td style="white-space: nowrap;text-align:right;">${items.isSalesTax ? `$${numberWithCommas(Number(orderItem.totalamout - items.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(orderItem.totalamout))}`}</td>
              `: ""}
            </tr>
            ` ).join("") : ""} ${!(items?.customer?.hidePrice) ? ` ${items?.company.chargeStripeCardFees && (item?.stripeCardFees > 0) ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Handling Fee</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
            </tr>
            ` }` : ""} ${items?.isSalesTax && items.salesTaxFeeAmount > 0 ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Sales Tax</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(items?.salesTaxFeeAmount ?? 0))}</td>
            </tr>
            ` }` : ""} ` : ""}
          </tbody>
          ` : ` ${!item.isMinimumPrice ? `
          <tbody>
            <tr>
              <td style="white-space: nowrap;">${customStartCase(item.dumpRateType)}</td>
              <td>
                <div class="desc-list-items">
                  <p>Ticket: <span>#${item.ticketNumber}</span></p>
                  ${items.customerRefrence && items.customerRefrence !== "" ? `
                  <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                  ` : ""} ${_.get(items, "hideWeight", false) === false ? `
                  <p>Inbound Weight = <span>${numberWithCommas(Number(item.inboundWeight))}</span></p>
                  <p>Outbound Weight = <span>${numberWithCommas(Number(item.outboundWeight))}</span></p>
                  <p>Net Weight = <span>${numberWithCommas(Number(item.netWeight))}</span></p>
                  ` : ""}
                </div>
              </td>
              <td>${numberWithCommas(Number(item.units))}</td>
              ${!(items?.customer?.hidePrice) ? `
              <td>$${numberWithCommas(Number(item.dumpRate))}</td>
              <td style="text-align:right;">$${numberWithCommas(item.totalAmount)}</td>
              `: ""}
            </tr>
            ${!(items?.customer?.hidePrice) ? ` ${item.isEnvironmentalLoadFee ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Environmental Load Fee Amount</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item.environmentalLoadFeeAmount))}</td>
            </tr>
            ` }` : ""} ${item.isFuelSurchardeFee ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Fuel Surcharge Fee Amount</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item.fuelSurchargeTotal))}</td>
            </tr>
            ` }` : ""} ${item.isSalesTax && item.salesTaxFeeAmount > 0 ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Sales Tax</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item.salesTaxFeeAmount))}</td>
            </tr>
            ` }` : ""} ${items?.company.chargeStripeCardFees && (item?.stripeCardFees > 0) ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Handling Fee</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
            </tr>
            ` }` : ""} ` : ""}
          </tbody>
          ${item.additionalItems.length > 0 ? item.additionalItems.map((additionalItem) => `
          <tbody>
            <tr>
              <td style="white-space: nowrap;">${customStartCase(additionalItem.label)}</td>
              <td>
                <div class="desc-list-items">
                  <p>Ticket: <span>#${item.ticketNumber}</span></p>
                </div>
              </td>
              <td>${numberWithCommas(Number(additionalItem.quantity))}</td>
              ${!(items?.customer?.hidePrice) ? `
              <td>$${numberWithCommas(Number(additionalItem.value))}</td>
              <td style="text-align:right;">$${numberWithCommas(Number(additionalItem.quantity) * Number(additionalItem.value))}</td>
              `: ""}
            </tr>
          </tbody>
          ` ).join("") : ""} ` : `${`
          <tbody>
            <tr>
              <td style="white-space: nowrap;">${customStartCase(item.dumpRateType)}</td>
              <td>
                <div class="desc-list-items">
                  <p>Ticket: <span>#${item.ticketNumber}</span></p>
                  ${items.customerRefrence && items.customerRefrence !== "" ? `
                  <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                  ` : ""} ${_.get(items, "hideWeight", false) === false ? `
                  <p>Inbound Weight = <span>${numberWithCommas(Number(item.inboundWeight))}</span></p>
                  <p>Outbound Weight = <span>${numberWithCommas(Number(item.outboundWeight))}</span></p>
                  <p>Net Weight = <span>${numberWithCommas(Number(item.netWeight))}</span></p>
                  ` : ""}
                </div>
              </td>
              <td>${item.units}</td>
              ${!(items?.customer?.hidePrice) ? `
              <td>${item.isSalesTax ? `$${numberWithCommas(Number(item.dumpRate - item.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(item.dumpRate))}`}</td>
              <td style="text-align:right;">${item.isSalesTax ? `$${numberWithCommas(Number(item.totalAmount - item.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(item.totalAmount))}`}</td>
              ` : ""}
            </tr>
            ${item.isSalesTax && item.salesTaxFeeAmount > 0 ? `${`
            <tr>
              <td>&nbsp;</td>
              <td>Sales Tax</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style="text-align:right;">$${numberWithCommas(Number(item.salesTaxFeeAmount))}</td>
            </tr>
            ` }` : ""}
          </tbody>
          `}`} `} `).join("") : ""} ${!(items?.customer?.hidePrice) ? `
          <tbody class="print-table-tbody-footer">
            <tr>
              <td colspan="5"><strong>Total:</strong> $${numberWithCommas(round2decimal(Number(items.totalAmount)))}</td>
            </tr>
          </tbody>
          `: ""}
        </table>
        ${(items?.driverSignature || items?.truck?.plateImageUrl || items?.loadImageUrl) ? `
        <table class="print-default-table">
          <tbody>
            <tr>
              ${items?.driverSignature ? `
              <td>
                <strong>Driver Signature:</strong>
                <div><img style="height:60px;width:60px" src="${items?.driverSignature}" alt="" /></div>
              </td>
              ` : ''} ${items?.truck?.plateImageUrl ? `
              <td>
                <strong>License plate:</strong>
                <div><img style="height:60px;width:100px" src="${items?.truck?.plateImageUrl}" alt="" /></div>
              </td>
              ` : ''} ${items?.loadImageUrl ? `
              <td>
                <strong>Inbound Load:</strong>
                <div><img style="height:60px;width:100px" src="${items?.loadImageUrl}" alt="" /></div>
              </td>
              ` : ''}
            </tr>
          </tbody>
        </table>
        ` : ''}
      </div>
      <div class="print-divider" style="margin: auto 0;width: 100%;display: block;">
        <div style="border-bottom: 2px dashed black;margin: 10px 0;"></div>
      </div>
      <div class="print-view-bottom">
        <div class="print-view-bottom-inner">
          ${sisterCompany && typeof sisterCompany === 'object' && Object.keys(sisterCompany).length !== 0 ? `
          <table class="print-default-table" style="margin-bottom: 5px;">
            <tbody>
              <tr>
                <td>
                  <strong style=" text-decoration: underline; margin-bottom: 5px; display: block; "> Location </strong>
                  <p style=" line-height: 1.4; ">
                    ${company.businessInfo.address}<br />
                    ${format_address(company.businessInfo)}<br />
                    ${company.businessInfo.phone && company.businessInfo.phone.length > 0 ? ` ${phoneFormate(company.businessInfo.phone)} <br />
                    `: ""}
                  </p>
                </td>
                <td style="text-align: center;">
                  <div style="display: inline-block;text-align: center;margin-bottom: 5px;"><img style="max-width:300px;" src="${company.logo.length > 0 ? company.logo : 'Logo'}" /></div>
                  <div>
                    <p style=" line-height: 1.4; ">
                      79-51 Cooper Avenue <br />
                      Glendale, NY 11385<br />
                      718.424.0300 ext 126
                    </p>
                  </div>
                </td>
                <td style="text-align: right;">
                  <div>
                    <strong style=" text-decoration: underline; margin-bottom: 5px; display: block; "> Location </strong>
                    <p style=" line-height: 1.4; ">
                      ${sisterCompany.businessInfo.address}<br />
                      ${format_address(sisterCompany.businessInfo)} <br />
                      ${sisterCompany.businessInfo.phone && sisterCompany.businessInfo.phone.length > 0 ? ` ${phoneFormate(sisterCompany.businessInfo.phone)} <br />
                      `: ""}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          ` : `
          <table class="print-default-table" style="margin-bottom: 5px;">
            <tbody>
              <tr>
                <td>
                  <div style="display: block;text-align: left;margin-bottom: 10px;"><img style="max-height: 50px; max-width: 250px;" src="${company?.logo?.length > 0 ? company?.logo : 'Logo'}" /></div>
                  <p style=" line-height: 1.4; ">
                    <strong>${company?.businessInfo?.businessName ?? ""}</strong><br />
                    ${company?.businessInfo?.address ?? ""} <br />
                    ${format_address(company?.businessInfo)} <br />
                    ${company?.sisterCompany ? '' : company?.billingEmail} <br />
                    ${company?.businessInfo?.phone ?? ""}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          ` }
          <table class="print-default-table" style="margin-bottom: 5px; line-height: 1.4;">
            <tbody>
              <tr>
                <td>
                  <table class="print-default-table">
                    <tbody>
                      <tr>
                        <td><strong>Vehicle:</strong></td>
                        <td>
                          ${(items?.driver && items?.driver?.licenseNumber) || (items?.truck && items?.truck?.plateNumber) ? ` ${_.get(items, "driver.licenseNumber", "")} ${items?.driver ? (items?.driver?.licenseNumber
        && items?.truck?.plateNumber ? " | " : "") : ''} ${_.get(items, "truck.plateNumber", "")}` : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Customer:</strong></td>
                        <td>${items.customer.displayName}</td>
                      </tr>
                      <tr>
                        <td><strong>Account Type:</strong></td>
                        <td>${items.paymentTerms.toUpperCase() === "COD" ? `COD ${items.cardNumber ? `- ${items.cardNumber.slice(-4)}` : ""}` : "On Account"}</td>
                      </tr>
                      <tr>
                        <td><strong>Driver:</strong></td>
                        <td>${items?.driver ? items?.driver.firstName + " " + items?.driver.lastName : "N/A"}</td>
                      </tr>
                      <tr>
                        <td><strong>Origin City:</strong></td>
                        <td>${items.cityOfOrigin ? items.cityOfOrigin : "N/A"}</td>
                      </tr>
                      <tr>
                        <td><strong>Project:</strong></td>
                        <td>${items?.projects ? items?.projects?.projectName : "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="print-default-table">
                    <tbody>
                      <tr>
                        <td><strong>Ticket Number:</strong></td>
                        <td>${items.ticketNumber}</td>
                      </tr>
                      <tr>
                        <td><strong>Date:</strong></td>
                        <td>${moment(items.completedDate)?.tz(items.company?.timezone)?.format("l")}</td>
                      </tr>
                      <tr>
                        <td><strong>Inbound Time:</strong></td>
                        <td>${items?.orderCreationType !== "createCharge" ? moment(items.inboundWeightDate)?.tz(items.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                      </tr>
                      <tr>
                        <td><strong>Outbound Time:</strong></td>
                        <td>${items?.orderCreationType !== "createCharge" ? moment(items.outboundWeightDate)?.tz(items.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                      </tr>
                      <tr>
                        <td><strong>Payment Type:</strong></td>
                        <td>
                          ${_.get(items, "paymentType", "") === "" ? _.get(items, "splitPayment", []).length > 0 ? `${items.splitPayment.map((payment, index) => (`${_.startCase(payment.paymentType) + (index <
          items.splitPayment.length - 1 ? ", " : " ")}`)).join("")}` : items.paymentTerms === "account" ? "Account" : "N/A" : `${_.startCase(_.get(items, "paymentType", "N/A"))}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="print-default-table">
                    <tbody>
                      ${_.get(items, "hideWeight", false) === false ? `
                      <tr>
                        <td><strong>Gross:</strong></td>
                        <td>${items.inboundWeight} lbs</td>
                      </tr>
                      <tr>
                        <td><strong>Tare:</strong></td>
                        <td>${items.outboundWeight} lbs</td>
                      </tr>
                      <tr>
                        <td><strong>Net:</strong></td>
                        <td>${items.netWeight} lbs</td>
                      </tr>
                      <tr>
                        <td><strong>Tons:</strong></td>
                        <td>${items.tonnage}</td>
                      </tr>
                      ` : ""}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="print-table" style=" line-height: 1.4;">
            <thead>
              <tr>
                <th>Materials & Services</th>
                <th>Description</th>
                <th>Unit</th>
                ${!(items?.customer?.hidePrice) ? `
                <th>Rate</th>
                <th style="text-align:right;">Amount</th>
                `: ""}
              </tr>
            </thead>
            ${arrangeOrderData.length > 0 ? arrangeOrderData.map((item) => ` ${item.orderCreationType === "createCharge" ? !item.isMinimumPrice ? `
            <tbody>
              ${item.fixedItems && item.fixedItems.length > 0 ? item.fixedItems.map((orderItem) => `
              <tr>
                <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
                <td>
                  <div class="desc-list-items">
                    <p>Ticket: <span>#${item.ticketNumber}</span></p>
                    ${items.customerRefrence && items.customerRefrence !== "" ? `
                    <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                    ` : ""}
                  </div>
                </td>
                <td>${orderItem.units}</td>
                ${!(items?.customer?.hidePrice) ? `
                <td>$${numberWithCommas(Number(orderItem.unitPrice))}</td>
                <td style="white-space: nowrap;text-align:right;">$${numberWithCommas(Number(orderItem.totalamout))}</td>
                `: ""}
              </tr>
              ` ).join("") : ""} ${!(items?.customer?.hidePrice) ? ` ${items?.isEnvironmentalLoadFee ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Environmental Load Fee Amount</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(items?.environmentalLoadFeeAmount ?? 0))}</td>
              </tr>
              ` }` : ""} ${items?.isFuelSurchardeFee ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Fuel Surcharge Fee Amount</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(items?.fuelSurchardeFeeAmount ?? 0))}</td>
              </tr>
              ` }` : ""} ${items?.company.chargeStripeCardFees && item?.stripeCardFees > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Handling Fee</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
              </tr>
              ` }` : ""} ${items?.isSalesTax && items?.salesTaxFeeAmount > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Sales Tax</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(items?.salesTaxFeeAmount ?? 0))}</td>
              </tr>
              ` }` : ""} ` : ""}
            </tbody>
            `: `
            <tbody>
              ${item.fixedItems && item.fixedItems.length > 0 ? item.fixedItems.map((orderItem) => `
              <tr>
                <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
                <td>
                  <div class="desc-list-items">
                    <p>Ticket: <span>#${item.ticketNumber}</span></p>
                    ${items.customerRefrence && items.customerRefrence !== "" ? `
                    <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                    ` : ""}
                  </div>
                </td>
                <td>${orderItem.units}</td>
                ${!(items?.customer?.hidePrice) ? `
                <td>${items.isSalesTax ? `$${numberWithCommas(Number(orderItem.unitPrice - items.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(orderItem.unitPrice))}`}</td>
                <td style="white-space: nowrap;text-align:right;">${items.isSalesTax ? `$${numberWithCommas(Number(orderItem.totalamout - items.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(orderItem.totalamout))}`}</td>
                `: ""}
              </tr>
              ` ).join("") : ""} ${!(items?.customer?.hidePrice) ? ` ${items?.company.chargeStripeCardFees && (item?.stripeCardFees > 0) ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Handling Fee</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
              </tr>
              ` }` : ""} ${items.isSalesTax && items.salesTaxFeeAmount > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Sales Tax</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(items.salesTaxFeeAmount))}</td>
              </tr>
              ` }` : ""} ` : ""}
            </tbody>
            ` : ` ${!item.isMinimumPrice ? `
            <tbody>
              <tr>
                <td style="white-space: nowrap;">${customStartCase(item.dumpRateType)}</td>
                <td>
                  <div class="desc-list-items">
                    <p>Ticket: <span>#${item.ticketNumber}</span></p>
                    ${items.customerRefrence && items.customerRefrence !== "" ? `
                    <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                    ` : ""} ${_.get(items, "hideWeight", false) === false ? `
                    <p>Inbound Weight = <span>${numberWithCommas(Number(item.inboundWeight))}</span></p>
                    <p>Outbound Weight = <span>${numberWithCommas(Number(item.outboundWeight))}</span></p>
                    <p>Net Weight = <span>${numberWithCommas(Number(item.netWeight))}</span></p>
                    ` : ""}
                  </div>
                </td>
                <td>${numberWithCommas(Number(item.units))}</td>
                ${!(items?.customer?.hidePrice) ? `
                <td>$${numberWithCommas(Number(item.dumpRate))}</td>
                <td style="text-align:right;">$${numberWithCommas(item.totalAmount)}</td>
                `: ""}
              </tr>
              ${!(items?.customer?.hidePrice) ? ` ${item.isEnvironmentalLoadFee ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Environmental Load Fee Amount</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item.environmentalLoadFeeAmount))}</td>
              </tr>
              ` }` : ""} ${item.isFuelSurchardeFee ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Fuel Surcharge Fee Amount</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item.fuelSurchargeTotal))}</td>
              </tr>
              ` }` : ""} ${item.isSalesTax && item.salesTaxFeeAmount > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Sales Tax</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item.salesTaxFeeAmount))}</td>
              </tr>
              ` }` : ""} ${items?.company.chargeStripeCardFees && item?.stripeCardFees > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Handling Fee</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item?.stripeCardFees))}</td>
              </tr>
              ` }` : ""} ` : ""}
            </tbody>
            ${item.additionalItems.length > 0 ? item.additionalItems.map((additionalItem) => `
            <tbody>
              <tr>
                <td style="white-space: nowrap;">${customStartCase(additionalItem.label)}</td>
                <td>
                  <div class="desc-list-items">
                    <p>Ticket: <span>#${item.ticketNumber}</span></p>
                  </div>
                </td>
                <td>${numberWithCommas(Number(additionalItem.quantity))}</td>
                ${!(items?.customer?.hidePrice) ? `
                <td>$${numberWithCommas(Number(additionalItem.value))}</td>
                <td style="text-align:right;">$${numberWithCommas(Number(additionalItem.quantity) * Number(additionalItem.value))}</td>
                `: ""}
              </tr>
            </tbody>
            ` ).join("") : ""} ` : `${`
            <tbody>
              <tr>
                <td style="white-space: nowrap;">${customStartCase(item.dumpRateType)}</td>
                <td>
                  <div class="desc-list-items">
                    <p>Ticket: <span>#${item.ticketNumber}</span></p>
                    ${items.customerRefrence && items.customerRefrence !== "" ? `
                    <p>Cust. Ref.# <span style=" white-space: normal; word-break: break-all; display: inline-block; vertical-align: top; max-width: 240px;"> ${items.customerRefrence} </span></p>
                    ` : ""} ${_.get(items, "hideWeight", false) === false ? `
                    <p>Inbound Weight = <span>${numberWithCommas(Number(item.inboundWeight))}</span></p>
                    <p>Outbound Weight = <span>${numberWithCommas(Number(item.outboundWeight))}</span></p>
                    <p>Net Weight = <span>${numberWithCommas(Number(item.netWeight))}</span></p>
                    ` : ""}
                  </div>
                </td>
                <td>${item.units}</td>
                ${!(items?.customer?.hidePrice) ? `
                <td>${item.isSalesTax ? `$${numberWithCommas(Number(item.dumpRate - item.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(item.dumpRate))}`}</td>
                <td style="text-align:right;">${item.isSalesTax ? `$${numberWithCommas(Number(item.totalAmount - item.salesTaxFeeAmount))}` : `$${numberWithCommas(Number(item.totalAmount))}`}</td>
                ` : ""}
              </tr>
              ${item.isSalesTax && item.salesTaxFeeAmount > 0 ? `${`
              <tr>
                <td>&nbsp;</td>
                <td>Sales Tax</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="text-align:right;">$${numberWithCommas(Number(item.salesTaxFeeAmount))}</td>
              </tr>
              ` }` : ""}
            </tbody>
            `}`} `} `).join("") : ""} ${!(items?.customer?.hidePrice) ? `
            <tbody class="print-table-tbody-footer">
              <tr>
                <td colspan="5"><strong>Total:</strong> $${numberWithCommas(round2decimal(Number(items.totalAmount)))}</td>
              </tr>
            </tbody>
            `: ""}
          </table>
          ${(items?.driverSignature || items?.truck?.plateImageUrl || items?.loadImageUrl) ? `
          <table class="print-default-table">
            <tbody>
              <tr>
                ${items?.driverSignature ? `
                <td>
                  <strong>Driver Signature:</strong>
                  <div><img style="height:60px;width:60px" src="${items?.driverSignature}" alt="" /></div>
                </td>
                ` : ''} ${items?.truck?.plateImageUrl ? `
                <td>
                  <strong>License plate:</strong>
                  <div><img style="height:60px;width:100px" src="${items?.truck?.plateImageUrl}" alt="" /></div>
                </td>
                ` : ''} ${items?.loadImageUrl ? `
                <td>
                  <strong>Inbound Load:</strong>
                  <div><img style="height:60px;width:100px" src="${items?.loadImageUrl}" alt="" /></div>
                </td>
                ` : ''}
              </tr>
            </tbody>
          </table>
          ` : ''}
        </div>
      </div>

    </div>
  </body>
</html>


    `
return html;
}

// Order Recept 
export const PrintOrderReceipt=(value,last)=>{
  let  order = value.data;
   let arrangeOrderData = sort_by_descending(reArrangOrdersData([value.data]), "createdAt")
          const company = order.company
     let body1= `
       <style>
       @media print {
        html {
          size: A4;
          margin: 0mm;
          // page-break-after: avoid;
        }
        @page {
          page-break-after: always;
          page-break-before: always;
          margin: 0mm;
        }
        .invoice__each-transaction {
          page-break-after: always;
          page-break-before: always;
          position: relative;
          visibility: visible;
          left: 0;
          top: 0;
        }
        .print-table-block {
          page-break-after:always;
          page-break-after:${!last ? "always" : "avoid"} !important;
          page-break-before: always;
          position: relative;
        }
        .print-block {
          page-break-after: always;
          // page-break-after: ${!last ? "always" : "avoid"} !important;
          page-break-before: always;
      }
      .break-page-avoid{
        page-break-after: avoid !important;
      }
    }
    .nvoice-layout{
      width: 100%;
      max-width: 1024px;
      margin: 0px auto;
      padding: 0px 15px 15px 15px;
    }
    .nvoice-layout tr th,.nvoice-layout tr td{
      font-family: Arial;
      font-weight: 700;
      font-size: 11px;
      line-height: normal;
      color: #000000;
      text-align: left;
    }
    .nvoice-layout tr td{
      font-weight: 400;
      vertical-align: text-top;
    }
    .last-child{
      text-align: right !important;
    }
    /* Start table */
    .invoice-header-table{
      width: 100%;
      margin:0;
    }
    .invoice-header-table tr th{
      font-family: Arial;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      padding: 10px 0;
      text-align: center;
    }
    .invoice-header-table tr td{
      font-family: Arial;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #000000;
      padding: 2px 0px;
    }
    /* End table */
    /* Start table */
      .vehicle-customer-table{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .vehicle-customer-table table{
        width: auto;
      }
      .vehicle-customer-table table tr td:last-child{
        font-weight: 400;
        padding-left: 15px;
      }
      .first-table-child{
        margin-right: auto;
        width: 180px;
      }
      .second-table-child{
        margin: auto;
      }
      .last-table-child{
        margin-left: auto;
      }
      .first-table-child th,
      .second-table-child th,
      .last-table-child th{
        white-space: nowrap;
      }
    /* End table*/
    /* Start table*/
    .nots-table{
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
    }
    /* End table*/
    /* Start table*/
    .materials-services-table{
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
      border-collapse: collapse;
      background-color: white !important; 
      z-index:9999 !important;
    }
    
    .materials-services-table tr th,
    .materials-services-table tr td{
      border-bottom: 1px solid #000;
      padding: 10px 0;
    }
    .materials-services-table tr th:last-child,
    .materials-services-table tr td:last-child{
      text-align: right;
    }
    /* End table*/
    /* Start table*/
    .materials-ticket-table{
      width: auto;
    }
    .materials-ticket-table tr th,
    .materials-ticket-table tr td{
      border-bottom: 0px;
    }
    /* End table*/
    /* Start table*/
    .account-type-nadtodatl-table{
      width: 100%;
      margin-bottom: 15px;
    }
    .account-type-nadtodatl-table th,
    .account-type-nadtodatl-table td{
      border-bottom: 0px;
      vertical-align: top;
    }
    /* .account-type-nadtodatl-table table{} */
    .amount-total-table th{
      padding-right: 31px;
    }
    /* End table*/
    /* Start table*/
    .driver-signature-table {
      width: 100%;
      margin-bottom: 10px;
    }
    .driver-signature-table .signature-title{
      font-family: Arial;
      font-weight: 700;
      font-size: 14px;
      line-height: normal;
      color: #000000;
      display: inline-block;
      margin: 0px;
    }
    .driver-signature{
      min-width: 290px;
      border-bottom: 1px solid #000;
      display: inline-block;
    }
    /* End table*/

    </style>
  </head>
  <div class=${last ? "break-page-avoid" : "print-table-block"}>
  <div class=${last ? "break-page-avoid" : "print-block"}>
    <table class="nvoice-layout">
      <tbody>
        <tr>
          <td>
            <table class="invoice-header-table">
              <thead>
                <tr>
                  <th colspan="3" style="text-align: left;">
                    <img  style="max-height: 50px; max-width: 250px;" src=${order?.company?.logo?.length > 0 ? order?.company?.logo :Logo}>
                  </th>
                </tr>
                <tr>
                <td style="height: 20px;"></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  ${order?.company?.businessInfo?.address}
              <br />
              ${format_address(order?.company?.businessInfo)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="vehicle-customer-table">
              <tbody>
                <tr>
                  <td>
                    <table class="first-table-child">
                      <tbody>
                        <tr>
                          <th>Vehicle:</th>
                          <td>
                              ${(order?.driver && order?.driver?.licenseNumber)  || (order?.truck && order?.truck?.plateNumber)? 
                               ` ${_.get(order, "driver.licenseNumber", "")}
                              ${order?.driver ? order?.driver?.licenseNumber &&  order?.truck?.plateNumber ? " | " : "":''}
                              ${_.get(order, "truck.plateNumber", "")}`:'N/A'
                            }
                            </td>
                        </tr>
                        <tr>
                          <th>Customer:</td>
                          <td>${order?.customer?.displayName}</td>
                        </tr>
                        <tr>
                          <th>Driver:</th>
                          <td>${order?.driver?.firstName ? order?.driver?.firstName + " " + order?.driver?.lastName : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Origin City:</th>
                          <td>${order.cityOfOrigin ?order.cityOfOrigin:"N/A"}</td>
                        </tr>
                        ${order?.company?.serviceAddress && order?.serviceLocation &&  order?.serviceLocation !== "" ?  `<tr>
                                  <th>Service Location:</th>
                                  <td>${order.serviceLocation ? order.serviceLocation : ""}</td>
                                </tr>`:""}   
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="second-table-child">
                      <tbody>
                        <tr>
                          <th>Ticket Number:</th>
                          <td>${order.ticketNumber}</td>
                        </tr>
                        <tr>
                          <th>Date: </th>
                          <td>${moment(order.completedDate)?.tz(order.company?.timezone)?.format(
    "l"
  )}</td>
                        </tr>
                        <tr>
                          <th>Inbound Time:</th>
                          <td>${order?.orderCreationType !== "createCharge" ? moment(order.inboundWeightDate)?.tz(order.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Outbound Time:</th>
                          <td>${order?.orderCreationType !== "createCharge" ? moment(order.outboundWeightDate)?.tz(order.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                        </tr>
                        <tr>
                        <th>Payment Type:</th>
                        <td>${ _.get(order, "paymentType", "") === "" ? _.get(order, "splitPayment", []).length > 0
                          ? `${order.splitPayment.map((payment, index) =>(`${_.startCase(payment.paymentType) + (index < order.splitPayment.length - 1 ? ", " : " ")}`)).join("")}`
                         : order.paymentTerms === "account" ? "Account": "N/A"
                          : `${_.startCase(_.get(order, "paymentType", "N/A"))}`}</td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="last-table-child">
                      <tbody>
                        <tr>
                          <th>Gross:</th>
                          <td>${order.inboundWeight} lbs</td>
                        </tr>
                        <tr>
                          <th>Tare:</th>
                          <td>${order.outboundWeight} lbs</td>
                        </tr>
                        <tr>
                          <th>Net:</th>
                          <td>${order.netWeight} lbs</td>
                        </tr>
                        <tr>
                          <th>Tons:</th>
                          <td>${order.tonnage}</td>
                        </tr>
                        <tr>
                          <th>Yardage:</th>
                          <td>${order.yardage ? Number(order.yardage).toFixed(2) : "0"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="nots-table">
              <thead>
                <tr>
                  <th>Notes/Comments:</th>
                </tr>
                <tr>
                  <td>
                  ${order.notes ? order.notes.replace(/\n/g, '<br/>') : ""}
                  </td>
                </tr>
              </thead>
            </table>
            <table class="materials-services-table">
              <thead>
                <tr>
                  <th>Materials & Services</th>
                  <th>Description</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
                <tr>
                ${arrangeOrderData.length>0 ?
                  arrangeOrderData.map(
                    (item, index) =>
                      `
                     ${item?.orderCreationType === "createCharge"
                        ?  !item?.isMinimumPrice ? `
                                <tbody>
                      ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
                          .map(
                            (orderItem, index) =>
                              `
                          <tr>
                         <td style="white-space: nowrap;">${orderItem?.fixedItem}</td>
      
                         <td>                      
                            <div class="desc-list-items">
                             <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                           </div> 
                         </td>
                         <td>${orderItem?.units}</td>
                         <td>$${numberWithCommas(
                                Number(orderItem?.unitPrice)
                              )}</td>
                         <td style="white-space: nowrap;">$${numberWithCommas(Number(orderItem?.totalamout)
                              )}</td>
                         </tr>
                         `
                          )
                          .join(""): ""}
                          ${item?.isEnvironmentalLoadFee ?
                            `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Environmental Load Fee Amount</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td style="text-align:right;">$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                  </tr>`
                           }` :""}
                             ${item?.isFuelSurchardeFee ?
                            `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Fuel Surcharge Fee Amount</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td style="text-align:right;">$${numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                                  </tr>`
                           }` :""}
                          ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Handling Fee</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>`
                             }` :""}
                          </tbody>
                         `:`
                         <tbody>
               ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
                   .map(
                     (orderItem, index) =>
                       `
                   <tr>
                  <td style="white-space: nowrap;">${orderItem?.fixedItem}</td>

                  <td>                      
                     <div class="desc-list-items">
                      <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                    </div> 
                  </td>
                  <td>${orderItem?.units}</td>
                  <td>$${numberWithCommas(
                         Number(orderItem?.unitPrice)
                       )}</td>
                  <td style="white-space: nowrap;">$${numberWithCommas(Number(orderItem?.totalamout)
                       )}</td>
                  </tr>
                  `
                   )
                   .join(""): ""}
                   ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                       `${` <tr>
                             <td>&nbsp;</td>
                             <td>Handling Fee</td>
                             <td>&nbsp;</td>
                             <td>&nbsp;</td>
                             <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                             </tr>`
                      }` :""}
                   </tbody>
                  `
                        : ` ${ !item?.isMinimumPrice ?
                            `
                              <tbody>
                               <tr>
                                <td style="white-space: nowrap;">${customStartCase(
                                item?.dumpRateType
                                )}</td>
                                    <td>                      
                                        <div class="desc-list-items">
                                          <p>Ticket: <span>#${item?.ticketNumber
                                }</span></p>
                                          <p>Inbound Weight = <span>${numberWithCommas(
                                Number(item?.inboundWeight)
                              )}</span></p>
                                          <p>Outbound Weight = <span>${numberWithCommas(
                                Number(item?.outboundWeight)
                              )}</span></p>
                                          <p>Net Weight = <span>${numberWithCommas(
                                Number(item?.netWeight)
                              )}</span></p>
                                        </div> 
                                    </td> 
                                    <td> ${numberWithCommas(Number(item?.units))}</td>
                                    <td>$${numberWithCommas(
                                Number(item?.dumpRate)
                              )} </td>
                                    <td>$${numberWithCommas(item?.totalAmount)}
                                  </td>
                                  </tr>
                             ${item?.isEnvironmentalLoadFee ?
                              `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Environmental Load Fee Amount</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                  </tr>`
                               }` :""}
                              ${item?.isFuelSurchardeFee ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Fuel Surcharge Fee Amount</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                                    </tr>`
                             }` :""}
                             ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Handling Fee</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>`
                             }` :""}
                             </tbody>
                             ${item?.additionalItems?.length > 0 ? item?.additionalItems.map((additionalItem) => `
                              <tbody>
                               <tr>
                                <td style="white-space: nowrap;">${customStartCase(additionalItem?.label)}</td>
                                <td>                      
                                    <div class="desc-list-items">
                                      <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                                    </div> 
                                </td> 
                                <td> ${numberWithCommas(Number(additionalItem?.quantity))}</td>
                                <td>$${numberWithCommas(Number(additionalItem?.value))} </td>
                                <td>$${numberWithCommas(Number(additionalItem?.quantity) * Number(additionalItem?.value))}</td>
                               </tr>
                             </tbody>
                             ` ).join("") :""} `
                          : `${
                            `
                            <tbody>
                            <tr>
                             <td style="white-space: nowrap;">${customStartCase(
                             item?.dumpRateType
                             )}</td>
                                 <td>                      
                                     <div class="desc-list-items">
                                       <p>Ticket: <span>#${item?.ticketNumber
                             }</span></p>
                                       <p>Inbound Weight = <span>${numberWithCommas(
                             Number(item?.inboundWeight)
                           )}</span></p>
                                       <p>Outbound Weight = <span>${numberWithCommas(
                             Number(item?.outboundWeight)
                           )}</span></p>
                                       <p>Net Weight = <span>${numberWithCommas(
                             Number(item?.netWeight)
                           )}</span></p>
                                     </div> 
                                 </td> 
                                 <td> ${item?.units
                           }</td>
                                 <td>$${numberWithCommas(
                             Number(item?.dumpRate)
                           )} </td>
                                 <td>$${numberWithCommas(item?.totalAmount)}
                               </td>
                               </tr>
                          </tbody>`}` } `
                      } `
                  )
                  .join("") :""}
              </thead>
            </table>
            <table class="account-type-nadtodatl-table">
              <tbody>
                <tr>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <tr>
                              <th>Account Type:</th>
                              <td>${order.paymentTerms.toUpperCase() === "COD" ? `COD ${order.cardNumber ? `- ${order.cardNumber.slice(-4)}` : ""}` : "On Account" }</td>
                            </tr>
                          </td> 
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="amount-total-table last-table-child">
                      <tbody>
                        <tr>
                          <th>Total</th> 
                          <td class="last-child">$${numberWithCommas(
                        round2decimal(Number(order?.totalAmount))
                      )}</td> 
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="driver-signature-table">
              <tbody>
                <tr>
                  <td colspan="2">
                    <h2 class="signature-title">Driver Signature:	</h2>
                    <div class="driver-signature">
                    ${order.driverSignature ?
                    `<img src=${order.driverSignature} alt=''/>`
                      :''}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="height: 10px;"></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
    </div>
`;
return body1
}

export const PrintSisterCompanyOrderReceipt=(value,last,sustainabilityInfosTemplate)=>{
  let  order = value.data;
   let arrangeOrderData = sort_by_descending(reArrangOrdersData([value.data]), "createdAt")
          const company = order.company
     let body1= `
       <style>
       @media print {
        html {
          size: A4;
          margin: 0mm;
          // page-break-after: avoid;
        }
        @page {
          page-break-after: always;
          page-break-before: always;
          margin: 0mm;
        }
        .invoice__each-transaction {
          page-break-after: always;
          page-break-before: always;
          position: relative;
          visibility: visible;
          left: 0;
          top: 0;
        }
        .print-table-block {
          page-break-after:always;
          page-break-after:${!last ? "always" : "avoid"} !important;
          page-break-before: always;
          position: relative;
        }
        .print-block {
          page-break-after: always;
          // page-break-after: ${!last ? "always" : "avoid"} !important;
          page-break-before: always;
      }
      .break-page-avoid{
        page-break-after: avoid !important;
      }
    }
    .nvoice-layout{
      width: 100%;
      max-width: 1024px;
      margin: 0px auto;
      padding: 0px;
    }
    .nvoice-layout tr th,.nvoice-layout tr td{
      font-family: Arial;
      font-weight: 700;
      font-size: 10px;
      line-height: normal;
      color: #000000;
      text-align: left;
    }
    .nvoice-layout tr td{
      font-weight: 400;
      vertical-align: text-top;
    }
    .last-child{
      text-align: right !important;
    }
    /* Start table */
    .invoice-header-table{
      width: 100%;
    }
    .invoice-header-table tr th{
      font-family: Arial;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      padding: 10px 0;
      text-align: center;
    }
    .invoice-header-table tr td{
      font-family: Arial;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #000000;
      padding: 2px 0px;
    }
    /* End table */
    /* Start table */
      .vehicle-customer-table{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .vehicle-customer-table table{
        width: auto;
      }
      .vehicle-customer-table table tr td:last-child{
        font-weight: 400;
        padding-left: 15px;
      }
      .first-table-child{
        margin-right: auto;
        // width: 180px;
      }
      .second-table-child{
        margin: auto;
      }
      .last-table-child{
        margin-left: auto;
      }
      .first-table-child th,
      .second-table-child th,
      .last-table-child th{
        white-space: nowrap;
      }
    /* End table*/
    /* Start table*/
    .nots-table{
      width: 100%;
      margin-bottom: 0;
      text-align: left;
    }
    /* End table*/
    /* Start table*/
    .materials-services-table{
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
      border-collapse: collapse;
      background-color: white !important; 
      z-index:9999 !important;
    }
    
    .materials-services-table tr th,
    .materials-services-table tr td{
      border-bottom: 1px solid #000;
      padding: 6px 0;
    }
    .materials-services-table tr th:last-child,
    .materials-services-table tr td:last-child{
      text-align: right;
    }
    /* End table*/
    /* Start table*/
    .materials-ticket-table{
      width: auto;
    }
    .materials-ticket-table tr th,
    .materials-ticket-table tr td{
      border-bottom: 0px;
    }
    /* End table*/
    /* Start table*/
    .account-type-nadtodatl-table{
      width: 100%;
      margin-bottom: 10px;
    }
    .account-type-nadtodatl-table th,
    .account-type-nadtodatl-table td{
      font-size: 12px !important;
      border-bottom: 0px;
      vertical-align: top;
    }
    .account-type-nadtodatl-table th{
      font-weight: normal !important;
    }
    .amount-total-table th{
      padding-right: 31px;
    }
    /* End table*/
    /* Start table*/
    .driver-signature-table{
      width: 100%;.
      margin-bottom: 10px;
    }
    .driver-signature-table .signature-title{
      font-family: Arial;
      font-weight: 700;
      font-size: 14px;
      line-height: normal;
      color: #000000;
      display: inline-block;
      margin: 0px;
    }
    .driver-signature{
      min-width: 290px;
      border-bottom: 1px solid #000;
      display: inline-block;
    }
    .desc-list-items2 p {
      display: block;
      white-space: nowrap; 
      font-size: 10px;
      font-weight: 600;
      padding:0px;
     }
     .desc-list-items2 p span {
       white-space: nowrap; 
       font-weight: 400;
      } 
    .desc-list-items2 p {
      margin: 0;
      padding: 2px 0;
    }
    /* End table*/

    </style>
  </head>
  <div class=${last ? "break-page-avoid" : "print-table-block"}>
  <div class=${last ? "break-page-avoid" : "print-block"}>
    <table class="nvoice-layout">
      <tbody>
        <tr>
          <td>
            <table class="invoice-header-table">
              <thead>
                <tr>
                  <th colspan="3" style="text-align: center;">
                    <img  style="max-height: 50px; max-width: inherit;" src=${order?.company?.logo?.length > 0 ? order?.company?.logo :Logo}>
                  </th>
                </tr>
                <tr>
                <td style="height: 0px;"></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <h4 style="    
                    font-family: 'Arial';
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                    text-decoration: underline;
                    margin: 0;
                    ">Transaction Location</h4>
                    <p
                    style="font-family: 'Arial';
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: left;
                    color: #000000;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    " 
                    >
                    ${order?.company?.businessInfo?.address}
                    <br />
                    ${format_address(order?.company?.businessInfo)}
                    </P>
                  </th>                
                </tr>
              </tbody>
            </table>
            <table class="vehicle-customer-table">
              <tbody>
                <tr>
                  <td style="max-width: 150px;">
                    <table class="first-table-child">
                      <tbody>
                        <tr>
                          <th>Vehicle:</th>
                          <td>
                              ${(order?.driver && order?.driver?.licenseNumber)  || (order?.truck && order?.truck?.plateNumber)? 
                               ` ${_.get(order, "driver.licenseNumber", "")}
                              ${order?.driver ? order?.driver?.licenseNumber && order?.truck?.plateNumber ? " | " : "":''}
                              ${_.get(order, "truck.plateNumber", "")}`:'N/A'
                            }
                            </td>
                        </tr>
                        <tr>
                          <th>Customer:</td>
                          <td>${order?.customer?.displayName}</td>
                        </tr>
                        <tr>
                          <th>Driver:</th>
                          <td>${order?.driver?.firstName ? order?.driver?.firstName + " " + order?.driver?.lastName : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Origin City:</th>
                          <td>${order.cityOfOrigin ?order.cityOfOrigin:"N/A"}</td>
                        </tr>
                        ${order?.company?.serviceAddress && order?.serviceLocation &&  order?.serviceLocation !== "" ?  `<tr>
                                  <th>Service Location:</th>
                                  <td>${order.serviceLocation ? order.serviceLocation : ""}</td>
                                </tr>`:""}                       
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="second-table-child" style="margin: 0px;">
                      <tbody>
                        <tr>
                          <th>Ticket Number:</th>
                          <td>${order.ticketNumber}</td>
                        </tr>
                        <tr>
                          <th>Date: </th>
                          <td>${moment(order.createdAt)?.tz(order.company?.timezone)?.format(
                            "l"
                          )}</td>
                        </tr>
                        <tr>
                          <th>Inbound Time:</th>
                          <td>${order?.orderCreationType !== "createCharge" ? moment(order.inboundWeightDate)?.tz(order.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Outbound Time:</th>
                          <td>${order?.orderCreationType !== "createCharge" ? moment(order.outboundWeightDate)?.tz(order.company?.timezone)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Yardage:</th>
                          <td>${order.yardage ? Number(order.yardage).toFixed(2) : "0"}</td>
                        </tr>  
                      </tbody>
                    </table>
                  </td>             
                </tr>
              </tbody>
            </table>
            <table class="nots-table">
              <thead>
                <tr>
                  <td style="width: 90px;font-weight: 600;">Notes/Comments:</td>
                  <td>${order.notes ? order.notes.replace(/\n/g, '<br/>') : ""}</td>
                </tr>
              </thead>
            </table>
            <table class="materials-services-table">
              <thead>
                <tr>
                  <th>Materials & Services</th>
                  <th>Description</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
                <tr>
                ${arrangeOrderData.length>0 ?
                  arrangeOrderData.map(
                    (item, index) =>
                      `
                     ${item?.orderCreationType === "createCharge"
                        ?  !item?.isMinimumPrice ? `
                                <tbody>
                      ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
                          .map(
                            (orderItem, index) =>
                              `
                          <tr>
                         <td style="white-space: nowrap;">${orderItem?.fixedItem}</td>
      
                         <td>                      
                            <div class="desc-list-items2">
                             <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                           </div> 
                         </td>
                         <td>${orderItem?.units}</td>
                         <td>$${numberWithCommas(
                                Number(orderItem?.unitPrice)
                              )}</td>
                         <td style="white-space: nowrap;">$${numberWithCommas(Number(orderItem?.totalamout)
                              )}</td>
                         </tr>
                         `
                          )
                          .join(""): ""}
                          ${item?.isEnvironmentalLoadFee ?
                            `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Environmental Load Fee</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td style="text-align:right;">$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                  </tr>`
                           }` :""}
                             ${item?.isFuelSurchardeFee ?
                            `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Fuel Surcharge Fee</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td style="text-align:right;">$${numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                                  </tr>`
                           }` :""}
                          ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Handling Fee</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>`
                             }` :""}
                          </tbody>
                         `:`
                         <tbody>
               ${item?.fixedItems && item?.fixedItems.length > 0 ? item?.fixedItems
                   .map(
                     (orderItem, index) =>
                       `
                   <tr>
                  <td style="white-space: nowrap;">${orderItem?.fixedItem}</td>

                  <td>                      
                     <div class="desc-list-items2">
                      <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                    </div> 
                  </td>
                  <td>${orderItem?.units}</td>
                  <td>$${numberWithCommas(
                         Number(orderItem?.unitPrice)
                       )}</td>
                  <td style="white-space: nowrap;">$${numberWithCommas(Number(orderItem?.totalamout)
                       )}</td>
                  </tr>
                  `
                   )
                   .join(""): ""}
                   ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                       `${` <tr>
                             <td>&nbsp;</td>
                             <td>Handling Fee</td>
                             <td>&nbsp;</td>
                             <td>&nbsp;</td>
                             <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                             </tr>`
                      }` :""}
                   </tbody>
                  `
                        : ` ${ !item?.isMinimumPrice ?
                            `
                              <tbody>
                               <tr>
                                <td style="white-space: nowrap;">${customStartCase(
                                item?.dumpRateType
                                )}</td>
                                    <td>                      
                                        <div class="desc-list-items2">
                                          <p>Ticket: <span>#${item?.ticketNumber
                                }</span></p>
                                ${item.customerRefrence &&  item.customerRefrence !== "" ?
                                `<p style="margin:2 0;">Cust. Ref.# <span>${item.customerRefrence
                                }</span></p>`
                                : ""}
                                          <p>Inbound Weight = <span>${numberWithCommas(
                                Number(item?.inboundWeight)
                              )}</span></p>
                                          <p>Outbound Weight = <span>${numberWithCommas(
                                Number(item?.outboundWeight)
                              )}</span></p>
                                          <p>Net Weight = <span>${numberWithCommas(
                                Number(item?.netWeight)
                              )}</span>(Tons ${order?.tonnage})</p>
                                        </div> 
                                    </td> 
                                    <td> ${numberWithCommas(Number(item?.units))}</td>
                                    <td>$${numberWithCommas(
                                Number(item?.dumpRate)
                              )} </td>
                                    <td>$${numberWithCommas(item?.totalAmount)}
                                  </td>
                                  </tr>
                             ${item?.isEnvironmentalLoadFee ?
                              `${` <tr>
                                  <td>&nbsp;</td>
                                  <td>Environmental Load Fee</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>$${numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                  </tr>`
                               }` :""}
                              ${item?.isFuelSurchardeFee ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Fuel Surcharge Fee</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                                    </tr>`
                             }` :""}
                             ${company?.chargeStripeCardFees && (item?.stripeCardFees > 0) ?
                              `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Handling Fee</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>$${numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>`
                             }` :""}
                             </tbody>
                             ${item?.additionalItems?.length > 0 ? item?.additionalItems.map((additionalItem) => `
                              <tbody>
                               <tr>
                                <td style="white-space: nowrap;">${customStartCase(additionalItem?.label)}</td>
                                <td>                      
                                    <div class="desc-list-items2">
                                      <p>Ticket: <span>#${item?.ticketNumber}</span></p>
                                    </div> 
                                </td> 
                                <td> ${numberWithCommas(Number(additionalItem?.quantity))}</td>
                                <td>$${numberWithCommas(Number(additionalItem?.value))} </td>
                                <td>$${numberWithCommas(Number(additionalItem?.quantity) * Number(additionalItem?.value))}</td>
                               </tr>
                             </tbody>
                             ` ).join("") :""} `
                          : `${
                            `
                            <tbody>
                            <tr>
                             <td style="white-space: nowrap;">${customStartCase(
                             item?.dumpRateType
                             )}</td>
                                 <td>                      
                                     <div class="desc-list-items2">
                                       <p>Ticket: <span>#${item?.ticketNumber
                             }</span></p>
                             ${item.customerRefrence &&  item.customerRefrence !== "" ?
                                `<p style="margin:2 0;">Cust. Ref.# <span>${item.customerRefrence
                                }</span></p>`
                                : ""}
                                       <p>Inbound Weight = <span>${numberWithCommas(
                             Number(item?.inboundWeight)
                           )}</span></p>
                                       <p>Outbound Weight = <span>${numberWithCommas(
                             Number(item?.outboundWeight)
                           )}</span></p>
                                       <p>Net Weight = <span>${numberWithCommas(
                             Number(item?.netWeight)
                           )}</span> (Tons ${order?.tonnage})</p>
                                     </div> 
                                 </td> 
                                 <td> ${item?.units
                           }</td>
                                 <td>$${numberWithCommas(
                             Number(item?.dumpRate)
                           )} </td>
                                 <td>$${numberWithCommas(item?.totalAmount)}
                               </td>
                               </tr>
                          </tbody>`}` } `
                      } `
                  )
                  .join("") :""}
              </thead>
            </table>
            <table class="account-type-nadtodatl-table">
              <tbody>
                <tr>
                  <td>
                    <table>
                      <tbody>
                            <tr>
                              <th>Account Type:</th>
                              <td>${order.paymentTerms.toUpperCase() === "COD" ? `COD ${order.cardNumber ? `- ${order.cardNumber.slice(-4)}` : ""}` : " On Account" }</td>
                            </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="amount-total-table last-table-child">
                      <tbody>
                        <tr>
                          <th>Total:</th> 
                          <td class="last-child">$${numberWithCommas(
      round2decimal(Number(order?.totalAmount))
    )}</td> 
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="driver-signature-table">
              <tbody>
                <tr>
                  <td colspan="2">
                    <h2 class="signature-title">Driver Signature:	</h2>
                    <div class="driver-signature">
                    ${order.driverSignature ?
                    `<img src=${order.driverSignature} alt=''/>`
                      :''}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            ${ sustainabilityInfosTemplate ? sustainabilityInfosTemplate.length > 0 ? sustainabilityInfosTemplate :"" :"" }
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
    </div>
`;
return body1
}
 
// PR Print Receipt
export const PrintPaymentRecepit=(items,newdata,company,invoices,last,isView)=>{
  const { customer } = items;
  const body1 = `
  <html lang="en">
  <head>
    <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>Invoice</title>
  </head>
  <style>

    @media print {
      html {
        size: A3;
        zoom: ${!isView ? "75%" : "100%"};
        page-break-after: avoid !important;
      }
      @page {
        page-break-after: avoid !important;
      }
      .invoice__each-transaction {
        position: relative;
      }
      .invoice__each-transaction {
        visibility: visible;
        position: relative;
        left: 0;
        top: 0;
      }
      .print-table-block {
        // height: 100vh;
        position: relative;
      }
      .print-block {
        display: block;
        page-break-before:always;
    }
  }

</style>
<style>
.bill-description-table th,.bill-description-table td{
  font-family: Arial;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  border-bottom: 1px solid #000000;
  padding: 4px 17px;
  word-break: break-all;
  min-width: 72px;
  vertical-align: text-top;
}
.bill-description-table th{
  font-weight: 700;
  padding-bottom: 2px !important;
}     
.bill-description-table tr td{
  padding-bottom: 6px !important;
}     


.bill-description-table tr td:first-child,.bill-description-table tr th:first-child{
  padding-left: 0px;
  text-align: left;
}
.bill-description-table tr td:last-child,.bill-description-table tr th:last-child{
  padding-right: 0px;
  text-align: right;
  white-space: nowrap;
}
.amount-number{
    font-family: Arial;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
  }
  .bill-description-footer-table tr td{
    border: 0px;
  }

  .invoice-number-date-table td:first-child{
    max-width: 127px;
    vertical-align: text-top;
  }
  .ticket-col-table{
    width: 100%;
  }
  .ticket-col-table td{
    border-bottom: 0px;
    padding: 0px;
  }
  .ticket-col-table td:first-child{
    min-width: 60px !important;
  }
  .ticket-col-table td:last-child{
    min-width: 120px;
  }        
  .desc-list-items {
    
  }
  .desc-list-items p {
   display: block;
   white-space: nowrap; 
   font-size: 10px;
   font-weight: 600;
  }
  .desc-list-items p span {
    white-space: nowrap; 
    font-weight: 400;
   }   
</style>
<body style="page-break-after: avoid !important;">
<table width="100%" style="page-break-after: avoid !important;${company?.sisterCompanydetails?"table-layout: fixed;":""} max-width: 1024px;${!isView  ? "padding:0px 20px 15px 15px;":"margin: 0px auto;padding:10px 15px 15px 15px;"  }">
${company?.sisterCompanydetails && company?.sisterCompanydetails !== undefined ?`<thead >
            <tr style="vertical-align:top;">
              <th style="width: 21%;">
                <h4 style="
                  font-family: 'Arial';
                  font-size: 12px;
                  font-weight: 600;
                  text-align: left;
                  text-decoration: underline;
                  margin: 0px 0px 6px;
                  ">Location</h4>
                  <p
                  style="font-family: 'Arial';
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 15px;
                  text-align: left;
                  color: #000000;
                  word-wrap: break-word;
                  margin-top: 0px;
                  margin-bottom: 30px;
                  " 
                  >${company.businessInfo.address}<br/>
                  ${format_address(company.businessInfo)}<br/>                  
                  ${company.businessInfo.phone && company.businessInfo.phone.length > 0 ? ` ${phoneFormate(company.businessInfo.phone)} <br />`: "" }
                  </p>
                </th>
                <th style="text-align:center !important;width:54%;">
                  <img class="main__logo- empire__logo" style="padding-bottom:10px; max-width:410px; margin-left:15px;" src=${company.logo.length > 0 ? company.logo : Logo}>
                  <div style="    
                  font-family: 'Arial';
                  font-size: 12px;
                  font-weight: 600;
                  letter-spacing: 0.7;
                  margin: 0 auto;
                  margin-left:125px;
                  max-width: 200px;
                  margin-bottom:2px;
                  text-align:center !important;
                  ">79-51 Cooper Avenue <br/>Glendale, NY 11385<br/> 718.424.0300 ext 126</div> 
                </th>
                <th style="width: 25%;">
                  <div style=style="max-width: 164px; margin-left: auto;">
                  <h4 style=" 
                    font-family: 'Arial';
                    font-size: 12px;
                    font-weight: 600;
                    text-decoration: underline; 
                    text-align: right;
                    margin: 0px 0px 6px;
                    ">Location</h4>
                    <p
                    style="font-family: 'Arial';
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    text-align: right;
                    color: #000000;
                    margin-top: 0px;
                    margin-bottom: 30px;">
                    ${company?.sisterCompanydetails.businessInfo.address}<br/>
                    ${format_address(company?.sisterCompanydetails.businessInfo)}
                    <br/>
                    ${company?.sisterCompanydetails.businessInfo.phone && company?.sisterCompanydetails.businessInfo.phone.length > 0 ? ` ${phoneFormate(company?.sisterCompanydetails.businessInfo.phone)} <br />`: "" }
                    </p>
                  </div>
                </th>
              </tr>
            <tr>
          </tr>
          <tr>
            <td style="height: 25px;">&nbsp;</td>
          </tr>
        </thead>`:`
        <thead>
<tr>
<th colspan="3" style="text-align: left;">
  <img  style="max-height: 50px; max-width: 250px;" src=${company?.logo?.length > 0 ? company?.logo : Logo}>
</th>
</tr>
<!-- Gap Tr Start -->
<tr>
<td style="height: 20px;"></td>
</tr>
<tr>
  <th colspan="3">
    <p
    style="
    font-family: Arial;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
    " 
    >            
      ${company?.businessInfo?.address ?? ""}
      <br />
            ${format_address(company?.businessInfo)}
      <br />
     ${company?.sisterCompany ? '' : company?.billingEmail}
    </p>
  </th>
</tr>
</thead>
        ` }   
<tbody>
<tr>
  <td style="vertical-align: top; padding-right: 30px; max-width: 200px;">            
   ${customer?.displayName?.length > 0 ? `<div
      style="
      font-family: Arial;
      font-weight: 700;
      font-size: 10px;
      line-height: 18px;
      color: #000000;
      margin: 0px 0px 5px;
      width: 180px;
      "
      >${customer?.displayName}
    </div>`: ""}
    ${customer?.address?.length > 0 ? `<div
      style="
      font-family: Arial;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #000000;
      margin: 0px;
      width: 180px;
      "
      >${customer?.address}
    </div>`: ""}
    <div style="
      font-family: Arial;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #000000;
      word-wrap: break-word;
      margin: 0px;
      width: 180px;
      "
    > 
      ${format_address(customer)?.length > 0 ? `${format_address(customer)} <br />`: ""}
      ${  customer?.phone?.length > 0 ?  `${phoneFormate(customer?.phone)} <br />` : "" }
      ${customer?.email?.length > 0 ? customer?.email : customer?.billingEmailAddress?.length > 0 ? customer?.billingEmailAddress : ""} 
    </div>
  </td>
  <td style="vertical-align: top; padding-left: 30px;">
    <table class="invoice-number-date-table" style="margin: auto;">
      <tbody>
        <tr>
          <td>
          <h3
            style="
            font-family: Arial;
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            min-width: 130px;
            margin: 0px;
            vertical-align: top;
            "
          >Payment Receipt:</h3>
          </td>
          <td>
          <p
            style="
            font-family: Arial;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            vertical-align: top;
            min-width: 128px;
            margin: 0px;
            "
          >${newdata?.paymentReceiptId ?? newdata?.invoiceId}   
          </p>
          </td>
        </tr>
        <tr>
        <td style="vertical-align: top;">
          <h3
            style="
            font-family: 'Arial';
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            display: inline;
            min-width: 127px;
            margin: 0px;
            vertical-align: top;
            "
          >Payment Date: </h3>
          </td>
          <td style="vertical-align: top;">
            <p
              style="
              font-family: Arial;
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              color: #000000;
              vertical-align: top;
              display: inline;
               margin: 0px;
              "
            > ${moment(newdata?.paymentDate)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l")}  
            </p>
          </td>                   
        </tr>
        <tr>
          <td <td style="vertical-align: top;">
          <h3
          style="
          font-family: 'Arial';
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          color: #000000;
          display: inline;
          min-width: 127px;
          margin: 0px;
          vertical-align: top;
          "
          >Payment Method: </h3>
          </td>
          <td style="vertical-align: top;">
            <p
            style="
            font-family: Arial;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            vertical-align: top;
            display: inline;
             margin: 0px;
             text-transform: capitalize;
            "
            > ${paymentMethodPosted({customerReceivePayments:{...newdata}})}  
            </p>
          </td>                   
        </tr>
      </tbody>
    </table>                       
  </td>
  <td 
    style="vertical-align: top;"
    >
    <h3
    style="
    font-family: Arial;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: #000000;
    display: inline-block;
    min-width: 127px;
    margin: 0px;
    "
  >Payment Amount</h3>
    <h3 class="amount-number"   
      > $${newdata?.isFromReceivePayment
        ? numberWithCommas(
            Number(
              newdata?.stripeCardFees +
              newdata?.amountToApply +
              newdata?.totalCreditAmount
            )
          )
        : numberWithCommas(
            Number(
              newdata?.amountToApply +
              newdata?.totalCreditAmount
            )
         )
        }</h3>
    </td>
</tr>
<!-- Gap Tr Start -->
<tr>
    <td style="height: 30px;"></td>
</tr>
<!-- Gap Tr End -->
<tr>
  <td colspan="3">
    <table class="bill-description-table" width="100%" style="border-collapse: collapse;">
      <thead>
        <tr>
          <th>
          Date
          </th>
          <th>
          Invoice #
          </th> 
          <th>
          Original Amount
          </th>
          <th>
          Open Balance 
          </th>
          <th>
          Payment Applied 
          </th>
        </tr>
      </thead> 
      <tbody>
      ${
        newdata &&
        _.get(newdata, "invoices", []).length > 0 ?
        newdata.invoices.map((e, i) => {
          return ` <tr>
                 <td> 
                    ${newdata?.paymentDate?moment(newdata?.paymentDate)?.tz(localStorage.getItem("timezone") || moment?.tz?.guess())?.format("l"):''}
                 </td>
                 <td style="white-space: nowrap;">
                 ${e.invoiceId}
                 </td>
                 <td>
                  $${numberWithCommas(
                    round2decimal(Number(e.total))
                  )}
                 </td> 
                 <td>
                  $${numberWithCommas(
                    round2decimal(Number(e.balacnce))
                  )}
                 </td>
                 <td>$${numberWithCommas(e.payment + e.stripeCardFees)}
                </td> 
          </tr>`;
        }).join(""):""
      }
      </tbody>
    </table>
  </td>
</tr>
</tbody>
<tfoot class="bill-description-footer-table" style="border-top: 1px solid #000;">
<!-- Gap Tr Start -->
<tr>
    <td style="height: 20px;"></td>
</tr>
<!-- Gap Tr End -->
<td colspan="4" style="vertical-align: top;">
    <table style="
      margin-left: auto;
      ">                                 
      <tr>
        <td style="text-align: right; padding: 4px 0px 4px 10px;">
          <h3 style=" 
          font-family: Arial;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          margin: 0px;
          display: inline-block;
          ">Amount Applied to Invoices: </h3>
            <p 
            style=" 
            font-family: Arial;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            margin: 0px;
            display: inline-block;
            "
        >$${numberWithCommas(
          round2decimal( invoices?.length === 1 ? Number(invoices[0]?.payment) : Number(_.get(newdata, "amountToApply", "")))
        )}
      </p>
    </td>
  </tr>
      ${newdata?.stripeCardFees > 0 && newdata?.isFromReceivePayment ? `<tr>
        <td style="text-align: right; padding: 4px 0px 4px 10px;">
          <h3 style=" 
          font-family: Arial;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          margin: 0px;
          display: inline-block;
          ">Handling Fee: </h3>
            <p 
            style=" 
            font-family: Arial;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            margin: 0px;
            display: inline-block;
            "
        >$${numberWithCommas(
          round2decimal(Number(_.get(newdata, "stripeCardFees", "")))
        )}
      </p>
    </td>
  </tr>`:""}
  <tr>
        <td style="text-align: right; padding: 4px 0px 4px 10px;">
            <h3 style=" 
            font-family: Arial;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            margin: 0px;
            display: inline-block;
            ">Amount Applied to Credit Memo: </h3>
            <p 
            style=" 
            font-family: Arial;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            margin: 0px;
            display: inline-block;
            "
        >$${_.get(newdata, "totalCreditAmount", "") ? numberWithCommas(round2decimal(_.get(newdata, "totalCreditAmount", ""))):"0.00"}
      </p>
    </td>
  </tr>
</table>                     
</td> 

</table>

</body>
</html>`;
return body1
}

export const  bottomContent = () =>{
  let html = `
  <div class="powered-by" 
  style="
    position: fixed;
    left: 0px;
    right: 0;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    color: #808080;
    padding: 12px 0;
    page-break-after: avoid;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1 !important;
  ">
    <span style="margin-right: 10px;">Powered by</span> <img src=${Logo} alt="WeighWorks" style="max-width: 120px;max-height:30px;"  />
  </div>
    `;
return html;
}

export const  bottomSisterCompanyContent = () =>{
  let html = `
  <div class="powered-by" 
    style="position: fixed;
    left: 0px;
    bottom: 20px;
    width: 100%;
    page-break-after: avoid;
    z-index:-1 !important;
    ">
    <div  style="width: 100%; max-width: 100%; text-align: center;">
      <h2 style="    
        font-family: Arial;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        text-decoration: underline;
        margin: 0;
        ">Please Remit Payment to:</h2>
      <h3 
        style="    
        font-family: Arial;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0;
        ">Empire Recycling Services<br/> 79-51 Cooper Avenue<br/> Glendale, NY 11385
      </h3>
    </div>
  </div>
    `;
return html;
}
