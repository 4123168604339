import React, { PureComponent } from "react";
import "../styles.scss";
import ReactModal from "react-modal";
import { PlushIcon, CloseIcon } from "../../../components/icons";
import moment from "moment-timezone";
import _ from "lodash";
import PrintReciptComponent from "./printRecpit";
import { message } from "antd";
import closeIcon from "../../../images/close_black.svg";
import {PrintPaymentRecepit } from "../../../utils/printInvoiceReceipt";
import { format_address, sort_by_descending, customStartCase,numberFormat} from "../../../constants/common";
import EmailAlert from "../../../utils/email_warning_popup";
// import { Spin } from "antd";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// import { LoadingOutlined } from "@ant-design/icons";
export default class InvoiceDetailComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      toggleMenu: false,
      // activeUrl: "",
      modelData: {},
      reRender: false,
      isPrintRecipt: false,
      invoiceEmailPleaseWait: false,
      isModelOpen: false,
      pleaseWait: false,
      reArrangOrders: [],
      loaderForPrint: false,
      isTicketInvoiceLoader: false,
      PO: "",
      API_URL:process.env.REACT_APP_API_HOSTNAME,
      manifest: "",
      isSisterCompany:false,
      sisterCompanyData:{},
      showModal:false,
      tz
    };
  }
  closeModalAlert = () => {
    this.setState({ showModal: false });
  };
  componentDidMount = async () => {
   await this.fetchProjectById()
   this.filteredPRsForInvoice()
   if(this.props.modelData?.company[0]?.sisterCompany){
    const {
      value: { status,data },
    } = await this.props.fetchSetting(this.props.modelData?.company[0]?.sisterCompany);
     if(status){
       this.setState({sisterCompanyData:data,isSisterCompany:true})
     }
  }
    this.setState({ modelData: this.props.modelData, PO: this.props.modelData?.PO, manifest: this.props.modelData?.manifest});
    this.setState({
      invoiceEmailPleaseWait: this.props.PrintDropDown,
      isModelOpen: this.props.isModelOpen,
    });
    this.setState({ isPrintRecipt: this.props.printByDropDown });
    this.calculateModel();
  };

  filteredPRsForInvoice = async () =>{
    const payload = {
      customerId: this.props.modelData?.customerId,
      invoiceId: this.props.modelData?.invoiceId,
    }
    const {
        value: {
            data: { paymentInvoices }
        }
    } = await this.props.getPaymentOfInvoice(payload);
    // const filteredPR =  paymentInvoices?.filter((items)=>items?.isRecivePaymentInvoicesId?.includes(this.props.modelData?.invoiceId))
    this.props.modelData.invoicePRs = paymentInvoices;
    this.setState({ modalData:this.props.modelData  })
  }
  fetchProjectById= async () =>{
    const payload = {
      companyId: this.props.modelData?.companyId,
      projectId: this.props.modelData?.projectId,
  }
  const {
    value: {
        data
    }
} = await this.props.getProjectByProjectId(payload);
console.log(data)
    this.setState({ projectData: data })
  }

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  calculateModel = () => {
    const { order } = this.props.modelData;
    const { reArrangOrders } = this.state;    
    if (order.length > 0) {
      order.map((item) => {
        let obj ={}
        if((this.state?.projectData && this.state?.projectData?.projectName?.length > 0)){
         obj ={}
        }else{
          if(item?.project?.projectName !== ""){
            obj = {
              projectId: item?.projectId,
              project: item?.project,
            };
          }
        }
        if (item?.orderCreationType === "createCharge") {
          if (item?.orderBreakDownItems?.isminimumPrice) {
            obj = {
              ...obj,
              fixedItems: [],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType,
              customerRefrence: item?.customerRefrence,
              stripeCardFees: item?.stripeCardFees,
              paymentType: item?.paymentType,
              isRoundOffAmount: item?.isRoundOffAmount,
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              isSalesTax: item.isSalesTax,
              salesTaxFeeAmount: item.salesTaxFeeAmount,
              isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
              createdAt: item?.createdAt,
              totalAmount: item?.totalAmount,
            };
            // Handling fixed items
            if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
              item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                let fixedItemObject = {
                  fixedItem: fixedItems?.fixedItem,
                  unitPrice: item?.orderBreakDownItems?.minimumPrice,
                  units: 1,
                  totalamout: Number(item?.orderBreakDownItems?.minimumPrice),
                };
                obj.fixedItems.push(fixedItemObject);
                return null;
              });
            }
            // Handling charge by weight items
            if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
              item.orderBreakDownItems.chargeByWeight.map((chargeByWeightItems) => {
                let chargeByWeightItemsObject = {
                  fixedItem: chargeByWeightItems?.fixedItem,
                  unitPrice: chargeByWeightItems?.unitPrice,
                  units: chargeByWeightItems?.units,
                  totalamout: Number(numberFormat(this.round2decimal(chargeByWeightItems?.unitPrice * chargeByWeightItems?.units))),
                };
                obj.fixedItems.push(chargeByWeightItemsObject);
                return null;
              });
            }
            reArrangOrders.push(obj);
          } else {          
            if (item?.orderBreakDownItems?.isCapOutPrice) {
              obj = {
                ...obj,  // Keep the previous values
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                customerRefrence: item?.customerRefrence,
                stripeCardFees: item?.stripeCardFees,
                paymentType: item?.paymentType,
                isRoundOffAmount: item?.isRoundOffAmount,
                isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item?.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
                isSalesTax: item.isSalesTax,
                salesTaxFeeAmount: item.salesTaxFeeAmount,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
                createdAt: item?.createdAt,
                totalAmount: item?.totalAmount,
              };
              // Handling fixed items
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems?.fixedItem);
                  let fixedItemObject = {
                    fixedItem: fixedItems.fixedItem,
                    unitPrice:
                      fixedItemName.includes("Yard") &&
                      Number(fixedItems?.unitPrice) > Number(item?.orderBreakDownItems?.capoutPrice)
                        ? item?.orderBreakDownItems?.capoutPrice
                        : fixedItems?.unitPrice,
                    units: fixedItems.units,
                    totalamout:Number(numberFormat(this.round2decimal( fixedItems?.unitPrice * fixedItems.units))),
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              // Handling charge by weight items
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightFixedItems) => {
                  let chargeByWeightItemObject = {
                    fixedItem: chargeByWeightFixedItems?.fixedItem,
                    unitPrice: chargeByWeightFixedItems?.unitPrice,
                    units: chargeByWeightFixedItems?.units,
                    totalamout: Number(numberFormat(this.round2decimal(chargeByWeightFixedItems?.unitPrice * chargeByWeightFixedItems?.units))),
                  };
                  obj.fixedItems.push(chargeByWeightItemObject);
                  return null;
                });
              }
              reArrangOrders.push(obj);
            } else {
              obj = {
                ...obj,
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                customerRefrence: item?.customerRefrence,
                stripeCardFees: item?.stripeCardFees,
                paymentType: item?.paymentType,
                isRoundOffAmount: item?.isRoundOffAmount,
                isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item?.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
                isSalesTax: item.isSalesTax,
                salesTaxFeeAmount: item.salesTaxFeeAmount,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
                createdAt: item?.createdAt,
                totalAmount: item?.totalAmount,
              };
              // Handling fixed items
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: Number(numberFormat(this.round2decimal(fixedItems?.unitPrice * fixedItems?.units))),
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              // Handling charge by weight items
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightItems) => {
                  let chargeByWeightItemsObject = {
                    fixedItem: chargeByWeightItems?.fixedItem,
                    unitPrice: chargeByWeightItems?.unitPrice,
                    units: chargeByWeightItems?.units,
                    totalamout: Number(numberFormat(this.round2decimal(chargeByWeightItems?.unitPrice * chargeByWeightItems?.units))),
                  };
                  obj.fixedItems.push(chargeByWeightItemsObject);
                  return null;
                });
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          // Case where orderCreationType is not 'createCharge'
          let total = 0;
          let dumpRate = 0;
          let isCapOutPrice = item?.pricingTierCapoutPrice > 0;
          let units = 0;
          let pricingTierMinimumPrice = 0;
          if (!item?.isMinimumPrice) {
            if (item?.dumpRateType.indexOf("Yard") !== -1) {
              units = Number(item?.yardage);
              if (item?.tonnageDifference < 0) {
                dumpRate = isCapOutPrice ? Number(item.pricingTierCapoutPrice) : Number(item?.dumpRate);
                total = isCapOutPrice ? Number(item?.capoutPrice) : Number(numberFormat(this.round2decimal(Number(item?.yardage) * Number(item?.dumpRate))));
              } else {
                dumpRate = Number(item?.dumpRate);
                total = Number(numberFormat(this.round2decimal(Number(item?.yardage) * Number(item?.dumpRate))));
              }
            } else {
              units = Number(item?.tonnage);
              if (item?.tonnageDifference < 0) {
                dumpRate = isCapOutPrice ? Number(item?.capoutPrice) : Number(item?.dumpRate);
                total = isCapOutPrice ? Number(item?.capoutPrice) : Number(numberFormat(this.round2decimal(Number(item?.tonnage) * Number(item?.dumpRate))));
              } else {
                dumpRate = Number(item?.dumpRate);
                total = Number(numberFormat(this.round2decimal(Number(item?.tonnage) * Number(item?.dumpRate))));
              }
            }
  
            obj = {
              ...obj,  // Keep the previous values
              inboundWeight: item?.inboundWeight,
              outboundWeight: item?.outboundWeight,
              tonnageDifference: item?.tonnageDifference,
              isCapped: item?.isCapped,
              customerRefrence: item?.customerRefrence,
              additionalItems: item?.additionalItems,
              dumpRate: dumpRate,
              netWeight: item?.netWeight,
              paymentType: item?.paymentType,
              isRoundOffAmount: item?.isRoundOffAmount,
              stripeCardFees: item?.stripeCardFees,
              totalAmount: total,
              units: units,
              dumpRateType: item?.dumpRateType,
              ticketNumber: item?.ticketNumber,
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item?.fuelSurchargeTotal,
              isSalesTax : item.isSalesTax,
              salesTaxFeeAmount : item.salesTaxFeeAmount,
              createdAt: item?.createdAt,
              totalOrderAmmount:item?.totalAmount
            };
            reArrangOrders.push(obj);
          } else {
            pricingTierMinimumPrice = item?.totalAmount - (item?.fuelSurchargeTotal + item?.environmentalLoadFeeAmount + item?.salesTaxFeeAmount);
            obj = {
              ...obj,  // Keep the previous values
              inboundWeight: item?.inboundWeight,
              outboundWeight: item?.outboundWeight,
              tonnageDifference: item?.tonnageDifference,
              isCapped: item?.isCapped,
              customerRefrence: item?.customerRefrence,
              additionalItems: item?.additionalItems,
              dumpRate: pricingTierMinimumPrice,
              netWeight: item?.netWeight,
              paymentType: item?.paymentType,
              isRoundOffAmount: item?.isRoundOffAmount,
              stripeCardFees: item?.stripeCardFees,
              totalAmount: pricingTierMinimumPrice,
              units: 1,
              dumpRateType: item?.dumpRateType,
              ticketNumber: item?.ticketNumber,
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item?.fuelSurchargeTotal,
              isSalesTax : item.isSalesTax,
              salesTaxFeeAmount : item.salesTaxFeeAmount,
              createdAt: item?.createdAt,
              totalOrderAmmount:item?.totalAmount
            };
            reArrangOrders.push(obj);
          }
  
        }
  
        return null;
      });
    }
  
    this.setState({
      reArrangOrders: reArrangOrders,
      reRender: !this.state.reRender,
    });
  };

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 1000) / 1000;
  };

  onChangeFile = async (e) => {
    // const file = e.target.files[0];
    const modelData = this.state.modelData;
    const imageShow = URL.createObjectURL(e.target.files[0]);
    this.getBase64(e.target.files[0], (result) => {
      modelData.customAttachments.push(result);
      modelData.showAttachments.push(imageShow);
      this.setState({ modelData: modelData }, () => {
        this.setState({ reRender: !this.state.reRender });
      });
    });
    this.setState({ reRender: !this.state.reRender });
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }

  numberWithCommas = (x) => {
    x = parseFloat(x)
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  sendInvoice = async () => {
    const { modelData } = this.state;
    const { company, customer, paymentTerms } = this.state.modelData;
    this.setState({ invoiceEmailPleaseWait: true });
    const invoice = this.state.modelData;
    const billingEmailAddress = this.state.modelData.customer.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress.trim() === "") {
      this.setState({ showModal: true ,
        invoiceEmailPleaseWait: false})
    } 
    else{
    const billingEmail =customer?.secondaryBillingContacts ? 
      customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
      :[]
    const payload = {
      company: {
        logo: company[0].logo,
        businessInfo: company[0]?.businessInfo,
        email: company[0]?.email,
        domain: company[0]?.domain,
        companyId: company[0]?._id,
        companyName: company[0]?.displayName,
        customerName: customer?.customerName,
        customerId: customer?._id,
      },
      customer: {
        email: customer.email,
        billingEmailAddress: [customer?.billingEmailAddress,...billingEmail],
        companyName: customer?.displayName,
        customerId: customer?._id,
        paymentType:customer?.paymentType,
        paymentTerms:paymentTerms,
      },
      invoice: {
        invoiceData: invoice,
        invoiceId: invoice?.invoiceId,
        dueDate: invoice?.dueDate,
        total: invoice?.total,
        balacnce: Number(invoice?.total) - Number(invoice.amountPaid),
        amountPaid: invoice?.amountPaid,
      },
      };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail(payload);
    if (status) {
      modelData.deliveryStatus = true;
      this.setState(
        {
          invoiceEmailPleaseWait: false,
          modelData: modelData,
          reRender: !this.state.reRender,
        },
        () => {
          message.success(showMessage);
        }
      );
    }}
  };

  closePrintRecipt = () => {
    if (this.props.printByDropDown) {
      this.setState({ isPrintRecipt: false });
      this.props.closeModel();
    } else {
      this.setState({ isPrintRecipt: false });
    }
  };

  updateInvoiceDetail = async () => {
    const { modelData } = this.state;
    modelData.PO = this.state.PO
    modelData.manifest = this.state.manifest
    // this.props.updateinvoiceDetailData(modelData)
    this.setState({ pleaseWait: true });
    const { uploadImage } = this.props;
    let attachments = [];
    let finalAttachments = [];
    if (
      modelData?.customAttachments &&
      modelData?.customAttachments.length > 0
    ) {
      modelData.customAttachments.forEach((item) => {
        attachments.push(uploadImage(this.submitImages(item)));
      });
    }
    const attachImage = await Promise.all(attachments);
    const attachImages = attachImage.map((item) => {
      return item.value.url;
    });
    finalAttachments = modelData?.attachments.concat(attachImages);
    const payload = {
      _id: modelData._id,
      notes: modelData.notes,
      attachments: finalAttachments,
      PO: this.state.PO,
      manifest: this.state.manifest
    };

    const {
      value: { status },
    } = await this.props.updateInvoicDetail(payload);
    if (status) {
      this.setState({ pleaseWait: false }, () => {
        this.props.closeModel();
      });
    }
  };

  cancleAttachments = (attachmentsIndex, type) => {
    const modelData = this.state.modelData;
    if (type === "FromDB") {
      modelData.attachments.splice(attachmentsIndex, 1);
    } else {
      modelData.showAttachments.splice(attachmentsIndex, 1);
      modelData.customAttachments.splice(attachmentsIndex, 1);
    }
    this.setState({ modelData: modelData, reRender: !this.state.reRender });
  };

  openBillingReciept = async (data) => {
    this.setState({loaderForPrint : true})
    const orderedArray =[data]
    const {value} = await this.props.batchPrintInvoice({
      invoicelist: orderedArray,
    });
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        };
      }
      this.setState({loaderForPrint : false})
    /* let printWindow = "";
    let printData = [data];
    const printPromises = printData.map((items, i) => {
      return new Promise(async (resolve, reject) => {
        let { order} = items;
        const orderData = this.reArrangOrdersData(sort_by_descending(order, "createdAt"))
        let body1=''
        if(this.state.isSisterCompany){
           body1=PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData)
          body1 += bottomSisterCompanyContent();
        }else{
          body1=PrintInvoiceReceipt(items)
          body1 += bottomContent();
        }
        for (let i = 0; i < orderData.length; i++) {
          let ordr = orderData[i];
          const { value } = await this.props.fetchOrder(ordr.ticketNumber);
          order = value.data;
          const last = orderData.length - 1 === i;
          if(this.state.isSisterCompany){
            body1 += PrintSisterCompanyOrderReceipt(value,last)
          }else{
            body1 += PrintOrderReceipt(value,last)
          }
        }
        printWindow += body1;
        resolve()
      });
    });

    Promise.all(printPromises)
    .then(() => { })
    .finally(()=>{
      let printInvoice = window.open("invoice", "_blank");
      printInvoice.document.write(printWindow);
      printInvoice.document.close(); 
      printInvoice.onload = function () {
        printInvoice.print();
      }
    });*/
  };

  getOrderInvoicePdf = async (ticketnumber) => {
    this.setState({ isTicketInvoiceLoader: true })
    const { value } = await this.props.getOrderInvoicePdf(ticketnumber)
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        }
      }
  }


  printPR = async (index, isView=false) => {
    let printWindow = "";
    let postedOrderData = this.state.modelData;
    const payload = {
      invoiceId: postedOrderData.invoicePRs[index]?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    const company = data?.company ? data?.company : postedOrderData?.company?.[0] ? postedOrderData?.company?.[0] : postedOrderData?.company;
    Object.assign(company, { sisterCompanydetails: this.state.isSisterCompany ? this.state.sisterCompanyData :undefined });

    const invoices = newdata && newdata.invoices;
    const printPromises = [postedOrderData].map((items, i) => {
      return new Promise((resolve, reject) => {
        const body1 = PrintPaymentRecepit(items,newdata,company,invoices,true,isView)
        printWindow += body1;
      });
    });

    Promise.all(printPromises).then(() => {});
    let printInvoice = window.open(`invoice`, "_blank");
    printInvoice.document.write(printWindow);
    if(!isView){
      printInvoice.print();
    }
  };

  render() {
    const { company, customer } = this.props.modelData;
    const {invoicePRs , stripeCardFees} = this.state.modelData
    const{ loaderForPrint} = this.state
    console.log('invoicePRs', invoicePRs)
    return (
      <>
      <>
        <ReactModal
          isOpen={this.state.isModelOpen}
          onRequestClose={() => {
            this.setState({ isModelOpen: false }, () => {
              this.props.closeModel();
            });
          }}
          contentLabel="Please Confirm"
        >
          <div className="react-modal-dialog react-modal-dialog-centered invoice__modal">
            {!this.state.isPrintRecipt && (
              <>
                <div className="react-modal-header">
                  <div className="btn-back-header">
                    <h5 className="react-modal-title">Invoice</h5>
                    <div className="page-header-btn-grp d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => {
                          this.sendInvoice();
                        }}
                        className="btn btn-dark mr-2"
                      >
                        {this.state.invoiceEmailPleaseWait
                          ? "Please wait ..."
                          : "Send Invoice"}{" "}
                      </button>
                      <button
                      type = "button"
                      disabled={this.props.modelData?.invoiceFrequency === 'monthly'}
                      className= "btn btn-dark"
                      onClick={()=>loaderForPrint === false ? this.openBillingReciept(this.props.modelData):undefined}>
                        {loaderForPrint === true ? "Please wait...":"Print Invoice"}{""}
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this.setState({ isModelOpen: false }, () => {
                        this.props.closeModel();
                      });
                    }}
                    type="button"
                    className="btn react-modal-close"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="react-modal-body">
                  <div className="invoice__address">
                    <ul className="list address__list">
                      {company?.length > 0 ? (
                        <li className="items">
                          <h3 className="address__title">
                            {company[0]?.businessInfo?.businessName}
                          </h3>
                          <p className="address">
                            {company[0]?.businessInfo?.address}
                            <br />
                            {format_address(company[0]?.businessInfo)}
                            <br />
                            {company[0]?.businessInfo?.phone}
                            <br />
                            {company[0]?.email}
                          </p>
                        </li>
                      ) : (
                        <li className="items">
                          <h3 className="address__title">
                            {company[0]?.businessInfo?.businessName}
                          </h3>
                          <p className="address">
                            {company?.businessInfo?.address}
                            <br />
                            {format_address(company?.businessInfo)}
                            <br />
                            {company?.businessInfo?.phone}
                            <br />
                            {company?.email}
                          </p>
                        </li>
                      )}
                      <li className="items">
                        <h3 className="address__title">Bill To </h3>
                        <p className="address">
                          {customer?.billingContactName}.<br />
                          {customer?.address}
                          <br />
                          {format_address(customer)}
                          <br />
                        </p>
                      </li>
                      <li className="items">
                        <div className="balance__due">
                          <h4>Balance Due</h4>
                          <h1 className="balance">
                            $
                            {this.numberWithCommas(
                              Number(this.props.modelData?.balacnce)
                            )}
                          </h1>
                        </div>
                      </li>
                    </ul>
                    <ul className="list address__list invoice-for-project-title">
                      <li className="items">
                        {this.state?.projectData?.projectName &&
                          <p className="address__title">
                            Invoice for Project: <span className="address">{this.state?.projectData?.projectName}</span>
                        </p>}
                      </li>
                    </ul>                    
                    <ul className="list address__list">
                      <li className="items"></li>
                      <li className="items"></li>
                      <li className="items">
                      {invoicePRs && invoicePRs.length > 0 ? invoicePRs.map((items,index)=>{
                        return !items?.isDeleted && (
                              <h3 className="address__title">Payment Receipt: <span className="underline" onClick={()=>this.printPR(index,true)}>{items?.invoiceId + " - $" + items.total}</span> <br/></h3>
                              ) 
                            }):null
                          }
                          </li>
                    </ul>
                  </div>
                  <div className="table-responsive">
                    <table className="table number-date-table" border="1">
                      <thead>
                        <tr>
                          <th>Invoice Number</th>
                          <th>PO</th>
                          <th>Manifest</th>
                          <th>Created Date</th>
                          <th>Due Date</th>
                          <th>Terms</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.props.modelData?.invoiceId}</td>
                          <td><input
                            className="form-control number-date-table-po-input"
                            value={this.state.PO}
                            onChange={(e) => { this.setState({ PO: e.target.value }) }}
                          ></input></td>
                          <td><input
                            className="form-control number-date-table-po-input"
                            value={this.state.manifest}
                            onChange={(e) => { this.setState({ manifest: e.target.value }) }}
                          ></input></td>
                          <td>
                            {moment(this.props.modelData?.invoiceDate)?.tz(this.state?.tz)?.format(
                              "l"
                            )}
                          </td>
                          <td>
                            {moment(this.props.modelData?.dueDate)?.tz(this.state?.tz)?.format("l")}
                          </td>
                          <td>
                            {customer?.paymentTerms === "account"
                              ? _.capitalize(customer?.paymentTerms)
                              : "COD"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <br />
                  <div className="table-responsive">
                    <table className="table amount-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Unit</th>
                          <th>Rate </th>
                          <th>Amount </th>
                        </tr>
                      </thead>
                      {this.state.reArrangOrders.length > 0 &&
                       sort_by_descending(this.state?.reArrangOrders, "createdAt")?.map((item, index) => (
                          <>
                            {item.orderCreationType === "createCharge" ? (
                              //   item?.orderBreakDownItems
                             !item.isMinimumPrice ? <>
                                <tbody className="dumping__services-table">
                                  {item?.fixedItems.length >
                                    0 &&
                                    item?.fixedItems.map(
                                      (orderItem, index) => (
                                        <>
                                          <tr>
                                            <td>
                                              {moment(
                                               item.createdAt
                                              )?.tz(this.state?.tz)?.format("l")}
                                            </td>
                                            <td>{orderItem?.fixedItem}</td>
                                            <td  onClick={() => { this.getOrderInvoicePdf(item?.ticketNumber) }}>
                                              <div className="underline mb-2">
                                                <strong>Ticket:</strong>#{" "}
                                                {item?.ticketNumber}
                                                {item.customerRefrence && item.customerRefrence !== "" &&
                                                <div className="description-items">
                                                  <strong>Cust. Ref.# </strong>
                                                  {item?.customerRefrence}
                                                  </div>      
                                                }
                                              </div>
                                              <div>
                                                {item.projectId && item?.projectId !== "" &&
                                                <div className="description-items">
                                                  <strong>Project :</strong>{" "}
                                                  {item?.project?.projectName}
                                                </div>}
                                              </div>
                                            </td>
                                           
                                            <td>
                                              {this.numberWithCommas(
                                                Number(orderItem?.units)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.unitPrice)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.totalamout)
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                      {item?.isEnvironmentalLoadFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Environmental Load Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                    </tr>}
                                  {item?.isFuelSurchardeFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Fuel Surcharge Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                                    </tr>}
                                       { company[0]?.chargeStripeCardFees && item?.stripeCardFees > 0  &&
                                     <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td> <span>Handling Fee</span></td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>
                                    }
                                    {item?.isSalesTax && item?.salesTaxFeeAmount > 0 &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Sales Tax</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.salesTaxFeeAmount))}</td>
                                    </tr>}
                                  {/* {item?.orderBreakDownItems?.chargeByWeight
                                    .length > 0 &&
                                    item?.orderBreakDownItems?.chargeByWeight.map(
                                      (orderItem, index) => (
                                        <>
                                          <tr>
                                            <td>
                                              {moment(
                                                this.props.modelData
                                                  ?.invoiceDate
                                              ).format("l")}
                                            </td>
                                            <td>{orderItem?.fixedItem}</td>
                                            <td>
                                              <strong>Ticket:</strong>#{" "}
                                              {item?.ticketNumber}
                                            </td>
                                            <td>
                                              {this.numberWithCommas(
                                                Number(orderItem?.units)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.unitPrice)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.units) *
                                                orderItem?.unitPrice
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )} */}
                                </tbody>
                              </>:
                              <>
                                <tbody className="dumping__services-table">
                                  {item?.fixedItems.length >
                                    0 &&
                                    item?.fixedItems.map(
                                      (orderItem, index) => (
                                        <>
                                          <tr>
                                            <td>
                                              {moment(
                                                item.createdAt
                                              )?.tz(this.state?.tz)?.format("l")}
                                            </td>
                                            <td>{orderItem?.fixedItem}</td>
                                            <td className="underline" onClick={() => { this.getOrderInvoicePdf(item?.ticketNumber) }}>
                                              <strong>Ticket:</strong>#{" "}
                                              {item?.ticketNumber}
                                              {item.customerRefrence && item.customerRefrence !== "" &&
                                      <div className="description-items">
                                      <strong>Cust. Ref.# </strong>
                                      {item?.customerRefrence}
                                      </div>
                                      }
                                       <td>
                                            {item.projectId && item?.projectId !== "" &&
                                      <div className="description-items">
                                        <strong>Project :</strong>{" "}
                                        {item?.project?.projectName}
                                      </div>}
                                      </td>
                                      </td>
                                            <td>
                                              {this.numberWithCommas(
                                                Number(orderItem?.units)
                                              )}
                                            </td>
                                            <td>
                                              ${this.numberWithCommas(Number(orderItem?.unitPrice))}
                                            </td>
                                              <td>
                                                ${this.numberWithCommas(Number(orderItem?.totalamout))}
                                              </td>
                                          </tr>
                                        </>
                                      ))}                                      
                                    {item?.isEnvironmentalLoadFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Environmental Load Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                    </tr>}
                                  {item?.isFuelSurchardeFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Fuel Surcharge Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.fuelSurchardeFeeAmount))}</td>
                                    </tr>}
                                       { company[0]?.chargeStripeCardFees && item?.stripeCardFees > 0  &&
                                     <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td> <span>Handling Fee</span></td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>
                                    }
                                    {item?.isSalesTax && item?.salesTaxFeeAmount > 0 &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Sales Tax</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.salesTaxFeeAmount))}</td>
                                    </tr>}
                                  {/* {item?.orderBreakDownItems?.chargeByWeight
                                    .length > 0 &&
                                    item?.orderBreakDownItems?.chargeByWeight.map(
                                      (orderItem, index) => (
                                        <>
                                          <tr>
                                            <td>
                                              {moment(
                                                this.props.modelData
                                                  ?.invoiceDate
                                              ).format("l")}
                                            </td>
                                            <td>{orderItem?.fixedItem}</td>
                                            <td>
                                              <strong>Ticket:</strong>#{" "}
                                              {item?.ticketNumber}
                                            </td>
                                            <td>
                                              {this.numberWithCommas(
                                                Number(orderItem?.units)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.unitPrice)
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {this.numberWithCommas(
                                                Number(orderItem?.units) *
                                                orderItem?.unitPrice
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )} */}
                                </tbody>
                              </>
                            ) : (
                              <>{!item?.isMinimumPrice ?
                                <><tbody className="dumping__services-table">
                                  <tr>
                                    <td>
                                      {moment(
                                        item.createdAt
                                      )?.tz(this.state?.tz)?.format("l")}
                                    </td>
                                    <td>{customStartCase(item?.dumpRateType)}</td>
                                    <td>
                                      <div onClick={() => { this.getOrderInvoicePdf(item?.ticketNumber); }} className="description-items underline">
                                        <strong>Ticket:</strong>#{" "}
                                        {item?.ticketNumber}
                                      </div>
                                      {item.customerRefrence && item.customerRefrence !== "" &&
                                      <div className="description-items">
                                      <strong>Cust. Ref.# </strong>
                                      {item?.customerRefrence}
                                      </div>
                                      }

                                      <div className="description-items">
                                        <strong>Inbound Weight =</strong>
                                        {item?.inboundWeight}
                                      </div>
                                      <div className="description-items">
                                        <strong>Outbound Weight =</strong>{" "}
                                        {item?.outboundWeight}
                                      </div>
                                      <div className="description-items">
                                        <strong>Net weight =</strong>{" "}
                                        {item?.netWeight}
                                      </div>
                                      {item.projectId && item?.projectId !== "" &&
                                      <div className="description-items">
                                        <strong>Project =</strong>{" "}
                                        {item?.project?.projectName}
                                      </div>}
                                    </td>
                                    <td>
                                      {this.numberWithCommas(Number(item?.units))}
                                      {/* {item?.dumpRateType.includes("Ton")
                                      ? item?.tonnage
                                  : item?.yardage} */}
                                    </td>
                                    <td>${this.numberWithCommas(Number(item?.dumpRate))}</td>
                                    <td>
                                      ${this.numberWithCommas(Number(item?.totalAmount))}
                                    </td>
                                  </tr>
                                  {item?.isEnvironmentalLoadFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Environmental Load Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                    </tr>}
                                  {item?.isFuelSurchardeFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Fuel Surcharge Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                                    </tr>}
                                    {item?.isSalesTax && item?.salesTaxFeeAmount > 0 &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Sales Tax</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.salesTaxFeeAmount))}</td>
                                    </tr>}
                                    {company[0]?.chargeStripeCardFees && item?.stripeCardFees > 0  &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td> <span>Handling Fee</span></td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>
                                    }
                                </tbody>
                                  {item?.additionalItems?.length > 0 && item?.additionalItems.map((additionalItem, index) =>
                                    <tbody className="dumping__services-table">
                                      <tr>
                                        <td>
                                          {moment(
                                            item?.createdAt
                                          )?.tz(this.state?.tz)?.format("l")}
                                        </td>
                                        <td>{customStartCase(additionalItem?.label)}</td>
                                        <td>
                                          <div onClick={() => { this.getOrderInvoicePdf(item?.ticketNumber); }} className="description-items underline">
                                            <strong>Ticket:</strong>#{" "}
                                            {item?.ticketNumber}
                                          </div>
                                          <div>
                                            {item.projectId && item?.projectId !== "" &&
                                              <div className="description-items">
                                                <strong>Project :</strong>{" "}
                                                {item?.project?.projectName}
                                              </div>}
                                          </div>
                                        </td>
                                        <td>
                                          {this.numberWithCommas(Number(additionalItem?.quantity))}
                                          {/* {item?.dumpRateType.includes("Ton")
                                                ? item?.tonnage
                                                : item?.yardage} */}
                                        </td>
                                        <td>${this.numberWithCommas(Number(additionalItem?.value))}</td>
                                        <td>
                                          ${this.numberWithCommas(Number(additionalItem?.quantity) * Number(additionalItem?.value))}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}</> :
                                <tbody className="dumping__services-table">
                                  <tr>
                                    <td>
                                      {moment(
                                        item?.createdAt
                                      )?.tz(this.state?.tz)?.format("l")}
                                    </td>
                                    <td>{customStartCase(item?.dumpRateType)}</td>
                                    <td>
                                      <div onClick={() => { this.getOrderInvoicePdf(item?.ticketNumber); }} className="description-items underline">
                                        <strong>Ticket:</strong>#{" "}
                                        {item?.ticketNumber}
                                      </div>
                                      {item.customerRefrence && item.customerRefrence !== "" &&
                                      <div className="description-items">
                                      <strong>Cust. Ref.# </strong>
                                      {item?.customerRefrence}
                                      </div>
                                      }
                                      <div className="description-items">
                                        <strong>Inbound Weight =</strong>
                                        {item?.inboundWeight}
                                      </div>
                                      <div className="description-items">
                                        <strong>Outbound Weight =</strong>{" "}
                                        {item?.outboundWeight}
                                      </div>
                                      {item.projectId && item?.projectId !== "" &&
                                      <div className="description-items">
                                        <strong>Project:</strong>{" "}
                                        {item?.project?.projectName}
                                      </div>}
                                    </td>
                                    <td>
                                      {this.numberWithCommas(Number(item?.units))}
                                      {/* {item?.dumpRateType.includes("Ton")
                                      ? item?.tonnage
                                  : item?.yardage} */}
                                    </td>
                                    <td>${this.numberWithCommas(Number(item?.dumpRate))}</td>
                                    <td>
                                      ${this.numberWithCommas(Number(item?.totalAmount))}
                                    </td>
                                  </tr>
                                  {item?.isEnvironmentalLoadFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Environmental Load Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.environmentalLoadFeeAmount))}</td>
                                    </tr>}
                                  {item?.isFuelSurchardeFee &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Fuel Surcharge Fee Amount</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.fuelSurchargeTotal))}</td>
                                    </tr>}
                                    {item?.isSalesTax && item?.salesTaxFeeAmount > 0 &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>Sales Tax</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.salesTaxFeeAmount))}</td>
                                    </tr>}
                                    {company[0]?.chargeStripeCardFees && item?.stripeCardFees > 0  &&
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td> <span>Handling Fee</span></td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                    </tr>
                                    }
                                </tbody>
                              }</>
                            )}
                          </>
                        ))}
                    </table>
                  </div>
                  <section className="invoice__not-section">
                    <div className="invoice__not-inner">
                      <div className="nots">
                        <h1 className="title">Notes</h1>
                        <textarea
                          value={this.state.modelData.notes}
                          onChange={(e) => {
                            const modelData = this.state.modelData;
                            modelData.notes = e.target.value;
                            this.setState({
                              modelData: modelData,
                              reRender: !this.state.reRender,
                            });
                          }}
                          className="textarea"
                        />
                      </div>
                      <div className="invoice__total">
                        <ul className="list">
                          {stripeCardFees > 0 ?
                            <li className="items">
                            <h3>Handling fee</h3>{" "}
                            <span>
                              $
                              {this.numberWithCommas(
                                this.round2decimal(
                                  Number(stripeCardFees)
                                )
                              )}
                            </span>
                          </li>:null}
                          <li className="items">
                            <h3>Invoice Total</h3>{" "}
                            <span>
                              $
                              {this.numberWithCommas(
                                this.round2decimal(
                                  Number(this.state.modelData?.total)
                                )
                              )}
                            </span>
                          </li>
                          <li className="items">
                            <h3>Amount Paid</h3>
                            <span>
                              $
                              {this.numberWithCommas(
                                this.round2decimal(
                                  Number(this.state.modelData?.amountPaid)
                                )
                              )}
                            </span>
                          </li>
                          <li className="items">
                            <h3>Open Balance</h3>
                            <span>
                              $
                              {this.numberWithCommas(
                                this.round2decimal(
                                  Number(
                                    this.state.modelData?.total -
                                    this.state.modelData.amountPaid
                                  )
                                )
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <section className="attachments__section">
                    <h3 className="label">Attachments</h3>
                    <ul className="list">
                      <li className="items d-flex">
                        <button
                          onClick={() => {
                            document.getElementById("Filepicker").click();
                          }}
                          className="attachments-btn"
                        >
                          <span>
                            <PlushIcon className="icon" />
                          </span>
                          <input
                            type="file"
                            id="Filepicker"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              this.onChangeFile(e);
                            }}
                          ></input>
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <ul className="list">
                          {this.state.modelData?.showAttachments?.length > 0 &&
                            this.state.modelData.showAttachments.map(
                              (item, index) => (
                                <li className="items">
                                  <button className="attachments-btn attachments-img">
                                    <img src={item} alt="" />
                                  </button>
                                  {/* <CloseIcon className="closeIcon"/> */}
                                  <img
                                    onClick={() => {
                                      this.cancleAttachments(index, "");
                                    }}
                                    className="closeIcon"
                                    src={closeIcon}
                                    alt=""
                                  />
                                </li>
                              )
                            )}
                          {this.state.modelData?.attachments?.length > 0 &&
                            this.state.modelData.attachments.map(
                              (item, index) => (
                                <li className="items">
                                  <button className="attachments-btn attachments-img">
                                    <img src={item} alt="" />
                                  </button>
                                  {/* <CloseIcon className="closeIcon"/> */}
                                  <img
                                    onClick={() => {
                                      this.cancleAttachments(index, "FromDB");
                                    }}
                                    className="closeIcon"
                                    src={closeIcon}
                                    alt=""
                                  />
                                </li>
                              )
                            )}
                        </ul>
                      </li>
                    </ul>
                    <br />
                    <div className="row">
                      <div className="col-lg-12">
                        <button
                          onClick={() => {
                            this.updateInvoiceDetail();
                          }}
                          className="btn btn-dark w-100"
                        >
                          {this.state.pleaseWait ? "Please Wait ..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              </>
            )}
          </div>
        </ReactModal>
        {this.state.isPrintRecipt && (
          <div className="pdf-container">
            <PrintReciptComponent
              printRecipt={this.state.modelData}
              closePrintRecipt={() => {
                this.closePrintRecipt();
              }}
            />
          </div>
        )}
      </>
      {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
     </>
    );
  }
}
